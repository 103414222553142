const BlogCard = ({ title, slug, date, main_img }) => {
  // Transforms date from ISO 8601 to 'DD MMM YYYY'
  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const options = { day: "numeric", month: "short", year: "numeric" };
    return date.toLocaleDateString("id-ID", options).replace(/\s/g, " ");
  };

  return (
    <div className="blog-card">
      <a href={`/artikel/${slug}`}>
        <img src={main_img} alt="Blog Card Image" className="blog-card-image" />
        <div className="blog-card-content">
          <p className="text-decoration-none blog-card-details">
            Bisnis & Keuangan
          </p>
          <h3 className="text-decoration-none blog-card-title">{title}</h3>
          <p className="text-decoration-none blog-card-details">
            {formatDate(date)}
          </p>
        </div>
      </a>
    </div>
  );
};

export default BlogCard;
