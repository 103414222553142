import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import "../styles/styles.css";
import "../styles/customstyle.css";
import BlogCard from "../components/BlogCard";
import FooterStandard from "../components/FooterStandard";
import ReactPaginate from "react-paginate";
import { Helmet, HelmetProvider } from "react-helmet-async";

const BlogPage = () => {
  const [isMobile, setMobile] = useState(window.innerWidth < 768);
  const [articleCards, setArticleCards] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [cardsPerPage, setCardsPerPage] = useState(
    window.innerWidth < 768 ? 8 : 12
  );

  // Page loading
  const { pageNumber } = useParams();
  const currentPage = parseInt(pageNumber, 10) || 1;

  // Pagination settings
  const handlePageClick = ({ selected }) => {
    navigate(`/artikel/page/${selected + 1}`);

    // Scroll to the top of the page
    const pageTopElement = document.getElementById("page-top");
    if (pageTopElement) {
      pageTopElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Hide or show navbar based on scroll position
  let lastScrollTop = 0;
  const limitHeight = 100;

  const handleMainNavScroll = () => {
    const st = window.scrollY;

    if (st > lastScrollTop && st > limitHeight) {
      document.getElementById("mainNav").classList.add("hidden");
    } else {
      document.getElementById("mainNav").classList.remove("hidden");
    }

    lastScrollTop = st;
  };

  // Misc functions
  const updateMobileSizeStatus = () => {
    setMobile(window.innerWidth < 768);

    if (isMobile) {
      setCardsPerPage(8);
    } else {
      setCardsPerPage(12);
    }
  };

  const navigate = useNavigate();
  const handleSectionNav = (id) => {
    navigate("/");
    setTimeout(() => {
      document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMobileSizeStatus);
    window.addEventListener("scroll", handleMainNavScroll);
    return () => {
      window.removeEventListener("resize", updateMobileSizeStatus);
      window.removeEventListener("scroll", handleMainNavScroll);
    };
  });

  // useEffect for article cards API call only
  useEffect(() => {
    const fetchArticleCards = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BLOG_BASE_URL}/blog/public/list`
        );
        const data = await response.json();
        if (data.blogs) {
          setArticleCards(data.blogs);
          setPageCount(Math.ceil(data.blogs.length / cardsPerPage));
        }
      } catch (error) {
        console.error("Error fetching articles:", error);
      }
    };

    fetchArticleCards();
  }, [currentPage, cardsPerPage]);

  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <meta
            name="keywords"
            content="UMKM, Payment, kasir, invoice, Merchant, toko, digital, pembayaran, QRIS"
          />
          <title>Ojire Anywhere | Artikel</title>
        </Helmet>
        <div id="page-top">
          {/* Navigation */}
          <Navbar
            expand="lg"
            fixed="top"
            bg="light"
            variant="light"
            id="mainNav"
            className="py-2 align-center"
          >
            <Container className="px-3 justify-content-between">
              <Navbar.Brand href="/">
                <img
                  src="/img/Logo Ojire.png"
                  alt="Ojire Logo"
                  style={{ margin: "8px" }}
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarTogglerDemo02">
                <a
                  className="nav-toggle"
                  onClick={() =>
                    document.querySelector(".navbar-toggler").click()
                  }
                >
                  <span className="bar"></span>
                  <span className="bar"></span>
                  <span className="bar"></span>
                </a>
              </Navbar.Toggle>
              <Navbar.Collapse id="navbarTogglerDemo02">
                <Nav
                  className={`navbar-nav my-2 my-lg-0 ${
                    isMobile ? "mx-4" : "mx-auto"
                  }`}
                >
                  <div
                    className="nav-link nav-layanan-pointer"
                    onClick={() => handleSectionNav("whyOjire")}
                  >
                    Kenapa Ojire
                  </div>
                  <div
                    className="nav-link nav-layanan-pointer"
                    onClick={() => handleSectionNav("fiturOjire")}
                  >
                    Fitur
                  </div>
                  <div
                    className="nav-link nav-layanan-pointer"
                    onClick={() => handleSectionNav("how")}
                  >
                    Cara Kerja
                  </div>
                  <div
                    className="nav-link nav-layanan-pointer"
                    onClick={() => handleSectionNav("faq")}
                  >
                    FAQ
                  </div>
                  <Nav.Link href="/artikel" className="active nav-link">
                    <span className="nav-link-last">Artikel</span>
                  </Nav.Link>
                </Nav>
                <div className="d-flex">
                  <a href="/demo">
                    <Button variant="primary">Hubungi Kami &gt;</Button>
                  </a>
                </div>
              </Navbar.Collapse>
            </Container>
          </Navbar>

          {/* Content Section*/}
          {articleCards ? (
            <section className="page-section" id="blogGallery">
              <div className="container">
                {/* Directory */}
                <div className="directory-container">
                  <img
                    src="/img/PNG/home-icon.png"
                    className={`directory-home-icon me-3 ${
                      isMobile ? "mb-2" : "mb-1"
                    }`}
                    height="22"
                    alt=""
                  />
                  <span className="me-3">
                    <a href="/" className="text-decoration-none directory-text">
                      Beranda
                    </a>
                  </span>
                  <span className="me-3 directory-arrow">&gt;</span>
                  <span>
                    <a
                      href="/artikel"
                      className="text-decoration-none directory-text"
                    >
                      Artikel
                    </a>
                  </span>
                </div>

                {/* Gallery */}
                <div className="blog-card-gallery mt-5">
                  {articleCards.map((article, index) => {
                    // Default values for resiliency
                    const defaultTitle = "Untitled Article";
                    const defaultSlug = "no-slug";
                    const defaultImgUrl = `${process.env.REACT_APP_BLOG_RESOURCES_URL}/v1/documents/file/ojire/blog/image/default_image.png`;

                    return (
                      <BlogCard
                        key={index}
                        title={article.title || defaultTitle}
                        slug={article.slug || defaultSlug}
                        date={article.created_at}
                        main_img={article.image || defaultImgUrl}
                      />
                    );
                  })}
                </div>

                {/* Pagination selector */}
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={1}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination-container"}
                  subContainerClassName={"pages pagination-sub-container"}
                  activeClassName={"pagination-active"}
                  pageClassName={"pagination-item"}
                  pageLinkClassName={"pagination-link"}
                  previousClassName={"pagination-previous"}
                  nextClassName={"pagination-next"}
                  previousLinkClassName={"pagination-previous-link"}
                  nextLinkClassName={"pagination-next-link"}
                  forcePage={currentPage - 1}
                />
              </div>
            </section>
          ) : null}
          {/* Content Section*/}

          {/* Footer */}
          <FooterStandard />
          {/* Footer*/}
        </div>
      </div>
    </HelmetProvider>
  );
};

export default BlogPage;
