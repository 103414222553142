import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import "../styles/styles.css";
import "../styles/customstyle.css";
import { Helmet, HelmetProvider } from "react-helmet-async";

const PrototypesPage = () => {
  const [isMobile, setMobile] = useState(window.innerWidth < 768);
  const [passwordInput, setPasswordInput] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Prototype Class 1
  const cardData1 = [
    {
      title: "First Close Circle Launch",
      version: "v1.1",
      link: "https://www.figma.com/proto/8EssU3rnNgcIljzemhHNqP/Ojire-Playground?node-id=0-1&node-type=canvas&viewport=-32874%2C-5883%2C0.6&t=PxUHktZCMYrc47li-0&scaling=min-zoom&content-scaling=fixed&starting-point-node-id=3714%3A28982&show-proto-sidebar=1https://www.figma.com/proto/8EssU3rnNgcIljzemhHNqP/Ojire-Playground?node-id=3714-28982&node-type=canvas&viewport=-32874%2C-5883%2C0.6&t=PxUHktZCMYrc47li-0&scaling=min-zoom&content-scaling=fixed&starting-point-node-id=3714%3A28982&hide-ui=1",
    },
    {
      title: "Revamp January",
      version: "v1.2",
      link: "https://www.figma.com/proto/P6lTyBN06vBMPgMRgrgm7U/Ojire-Anywhere-Prototypes?page-id=140%3A999&node-id=580-57786&node-type=frame&viewport=908%2C635%2C0.86&t=O0D0EUWX6HJOrrKQ-8&scaling=scale-down&content-scaling=fixed&starting-point-node-id=580%3A57786&hide-ui=1",
    },
  ];

  // Prototype Class 2
  const cardData2 = [
    {
      title: "General Overview",
      version: "v1.1",
      link: "https://www.figma.com/proto/K9uf9CMxmIZkcVaCVYyHNv/Ojire-Back-Office-Prototypes?page-id=0%3A1&node-id=1-5277&node-type=canvas&viewport=804%2C1066%2C0.16&t=gkxLk5sStWBmtBNZ-8&scaling=min-zoom&content-scaling=fixed&starting-point-node-id=1%3A5277&disable-default-keyboard-nav=1&hide-ui=1",
    },
    {
      title: "Merchant Onboarding Process",
      version: "v1.1",
      link: "https://www.figma.com/proto/K9uf9CMxmIZkcVaCVYyHNv/Ojire-Back-Office-Prototypes?page-id=1%3A12351&node-id=1-13708&node-type=section&viewport=885%2C747%2C0.09&t=mO5x2DunjsGdaJWy-1&scaling=min-zoom&content-scaling=fixed&starting-point-node-id=1%3A13708&share=1",
    },
    {
      title: "Bank Reconciliation",
      version: "v1.1",
      link: "https://www.figma.com/proto/K9uf9CMxmIZkcVaCVYyHNv/Ojire-Back-Office-Prototypes?page-id=1%3A12351&node-id=1-12424&node-type=section&viewport=885%2C747%2C0.09&t=mO5x2DunjsGdaJWy-1&scaling=min-zoom&content-scaling=fixed&starting-point-node-id=1%3A12424&share=1",
    },
    {
      title: "Add New Role & Ojirian",
      version: "v1.1",
      link: "https://www.figma.com/proto/K9uf9CMxmIZkcVaCVYyHNv/Ojire-Back-Office-Prototypes?page-id=1%3A12351&node-id=1-14446&node-type=section&viewport=885%2C747%2C0.09&t=mO5x2DunjsGdaJWy-1&scaling=min-zoom&content-scaling=fixed&starting-point-node-id=1%3A14446&share=1",
    },
  ];

  // Hide or show navbar based on scroll position
  let lastScrollTop = 0;
  const limitHeight = 100;

  const handleMainNavScroll = () => {
    const st = window.scrollY;

    if (st > lastScrollTop && st > limitHeight) {
      document.getElementById("mainNav").classList.add("hidden");
    } else {
      document.getElementById("mainNav").classList.remove("hidden");
    }

    lastScrollTop = st;
  };

  const updateMobileSizeStatus = () => {
    setMobile(window.innerWidth < 768);
  };

  const navigate = useNavigate();
  const handleSectionNav = (id) => {
    navigate("/");
    setTimeout(() => {
      document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  // For logging in to the repository
  const checkPassword = () => {
    if (passwordInput === "ojire123") {
      setIsAuthenticated(true);
      setErrorMessage("");
    } else {
      setErrorMessage("Kata sandi salah, silakan coba lagi.");
    }
  };

  const createCard = (data) => (
    <div className="card-rep" onClick={() => window.open(data.link, "_blank")}>
      <h4 className="card-title-rep">{data.title}</h4>
      <p className="card-version-rep">{data.version}</p>
    </div>
  );

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        checkPassword();
      }
    };

    const passwordInputElement = document.getElementById("rep-password-input");
    passwordInputElement?.addEventListener("keypress", handleKeyPress);
    window.addEventListener("resize", updateMobileSizeStatus);
    window.addEventListener("scroll", handleMainNavScroll);
    return () => {
      passwordInputElement?.removeEventListener("keypress", handleKeyPress);
      window.removeEventListener("resize", updateMobileSizeStatus);
      window.removeEventListener("scroll", handleMainNavScroll);
    };
  }, [passwordInput]);

  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <meta
            name="keywords"
            content="UMKM, Payment, kasir, invoice, Merchant, toko, digital, pembayaran, QRIS"
          />
          <title>Ojire Anywhere | Prototypes</title>
        </Helmet>
        <div id="page-top">
          {/* Navigation */}
          <Navbar
            expand="lg"
            fixed="top"
            bg="light"
            variant="light"
            id="mainNav"
            className={`py-2 align-center ${
              isAuthenticated ? "" : "blurred-rep no-select"
            }`}
          >
            <Container className="px-3 justify-content-between">
              <Navbar.Brand href="/">
                <img
                  src="/img/Logo Ojire.png"
                  alt="Ojire Logo"
                  style={{ margin: "8px" }}
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarTogglerDemo02">
                <a
                  className="nav-toggle"
                  onClick={() =>
                    document.querySelector(".navbar-toggler").click()
                  }
                >
                  <span className="bar"></span>
                  <span className="bar"></span>
                  <span className="bar"></span>
                </a>
              </Navbar.Toggle>
              <Navbar.Collapse id="navbarTogglerDemo02">
                <Nav
                  className={`navbar-nav my-2 my-lg-0 ${
                    isMobile ? "mx-4" : "mx-auto"
                  }`}
                >
                  <div
                    className="nav-link nav-layanan-pointer"
                    onClick={() => handleSectionNav("whyOjire")}
                  >
                    Kenapa Ojire
                  </div>
                  <div
                    className="nav-link nav-layanan-pointer"
                    onClick={() => handleSectionNav("fiturOjire")}
                  >
                    Fitur
                  </div>
                  <div
                    className="nav-link nav-layanan-pointer"
                    onClick={() => handleSectionNav("how")}
                  >
                    Cara Kerja
                  </div>
                  <div
                    className="nav-link nav-layanan-pointer"
                    onClick={() => handleSectionNav("faq")}
                  >
                    <span className="nav-link-last">FAQ</span>
                  </div>
                  <Nav.Link href="/artikel" className="nav-link">
                    <span className="nav-link-last">Artikel</span>
                  </Nav.Link>
                </Nav>
                <div className="d-flex">
                  <a href="/demo">
                    <Button variant="primary">Hubungi Kami &gt;</Button>
                  </a>
                </div>
              </Navbar.Collapse>
            </Container>
          </Navbar>

          {!isAuthenticated ? (
            <div
              id="rep-password-container"
              className="text-password-container-rep"
            >
              <input
                type="password"
                id="rep-password-input"
                placeholder="Masukkan Kata Sandi"
                className="styled-input-rep"
                value={passwordInput}
                onChange={(e) => setPasswordInput(e.target.value)}
              />
              <button onClick={checkPassword} className="styled-button-rep">
                Masuk
              </button>
              {errorMessage && (
                <p id="rep-error-message" className="error-message-pw-rep">
                  {errorMessage}
                </p>
              )}
            </div>
          ) : null}

          <div
            id="rep-main-content"
            className={`page-section ${
              isAuthenticated ? "" : "blurred-rep no-select"
            }`}
          >
            <div className="container text-center-rep">
              <h1 className="text-primary text-padding-title-rep">
                Selamat Datang di Repositori Prototype Ojire
              </h1>
              <h4 className="step-description text-margin-subtitle-rep">
                Silahkan Klik Kartu Untuk Mengakses Prototype
              </h4>
              <h3 className="step-description text-margin-section-rep">
                <span className="header-yellow">Ojire Anywhere</span>
              </h3>
              <div id="cards-container1" className="cards-container-rep">
                {cardData1.map((data, index) => createCard(data))}
              </div>
              <h3 className="step-description text-margin-section-rep">
                <span className="header-yellow">
                  Ojire Back Office Dashboard
                </span>
              </h3>
              <div id="cards-container2" className="cards-container-rep">
                {cardData2.map((data, index) => createCard(data))}
              </div>
            </div>
          </div>

          {/* Footer */}
          <footer
            className={`text-center footer-text-color footer-font-size ${
              isAuthenticated ? "" : "blurred-rep no-select"
            }`}
          >
            <section className="pt-2">
              <div className="container text-center text-md-start mt-4">
                {/* Grid row */}
                <div className="row">
                  {isMobile ? (
                    <div>
                      <a className="" href="#page-top">
                        <img
                          src="/img/Logo Ojire.png"
                          className="mb-5"
                          height="70"
                          alt=""
                        />
                      </a>
                      <div className="gap-3 d-flex justify-content-center mb-5">
                        <a
                          href="https://www.facebook.com/Ojire.ID"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="align-content-center text-reset"
                        >
                          <img
                            src="/img/PNG/facebook.png"
                            className=""
                            width="32"
                            alt=""
                          />
                        </a>
                        <a
                          href="https://id.linkedin.com/company/ojire"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="align-content-center text-reset"
                        >
                          <img
                            src="/img/PNG/linkedin.png"
                            className=""
                            width="32"
                            alt=""
                          />
                        </a>
                        <a
                          href="https://www.instagram.com/ojireofficial/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="align-content-center text-reset"
                        >
                          <img
                            src="/img/PNG/instagram.png"
                            className=""
                            width="32"
                            alt=""
                          />
                        </a>
                        <a
                          href="https://www.youtube.com/@Ojireofficial"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="align-content-center text-reset"
                        >
                          <img
                            src="/img/PNG/youtube.png"
                            className=""
                            width="32"
                            alt=""
                          />
                        </a>
                        <a
                          href="https://www.tiktok.com/@ojireofficial?is_from_webapp=1&sender_device=pc"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="align-content-center text-reset"
                        >
                          <img
                            src="/img/PNG/tiktok.png"
                            className=""
                            width="32"
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-between mb-3">
                      <a className="" href="#page-top">
                        <img
                          src="/img/Logo Ojire.png"
                          className=""
                          height="44"
                          alt=""
                        />
                      </a>
                      <div className="gap-3 d-flex">
                        <a
                          href="https://www.facebook.com/Ojire.ID"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="align-content-center text-reset"
                        >
                          <img
                            src="/img/PNG/facebook.png"
                            className=""
                            width="32"
                            alt=""
                          />
                        </a>
                        <a
                          href="https://id.linkedin.com/company/ojire"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="align-content-center text-reset"
                        >
                          <img
                            src="/img/PNG/linkedin.png"
                            className=""
                            width="32"
                            alt=""
                          />
                        </a>
                        <a
                          href="https://www.instagram.com/ojireofficial/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="align-content-center text-reset"
                        >
                          <img
                            src="/img/PNG/instagram.png"
                            className=""
                            width="32"
                            alt=""
                          />
                        </a>
                        <a
                          href="https://www.youtube.com/@Ojireofficial"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="align-content-center text-reset"
                        >
                          <img
                            src="/img/PNG/youtube.png"
                            className=""
                            width="32"
                            alt=""
                          />
                        </a>
                        <a
                          href="https://www.tiktok.com/@ojireofficial?is_from_webapp=1&sender_device=pc"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="align-content-center text-reset"
                        >
                          <img
                            src="/img/PNG/tiktok.png"
                            className=""
                            width="32"
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                  )}
                  {/* Offices column */}
                  <div className="col-md-4 col-lg-4 col-xl-4 px-sm-2 px-5 mx-auto my-3">
                    {/* Content */}
                    <div className="d-flex mb-2 footer-address-center">
                      <i className="fa-solid fa-location-dot fa-lg me-2 mt-1"></i>
                      <span className="fw-bold footer-font-size footer-office-indent">
                        Kantor Pusat
                      </span>
                    </div>
                    <div className="d-flex footer-address-center">
                      <a
                        href="https://goo.gl/maps/7P7Bt21Wqhf7xP46A"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-decoration-none footer-text-color footer-font-small footer-link-highlight footer-address-indent"
                      >
                        Gedung One Pacific Place Lt. 15, Suite 15-01 Jl. Jendral
                        Sudirman Kav. 52-53, Jakarta 12190
                      </a>
                    </div>
                    <div className="d-flex footer-address-center mb-4">
                      <a
                        href="tel:+622125502533"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-decoration-none footer-text-color footer-font-small footer-link-highlight footer-address-indent"
                      >
                        (021) 2550 2533
                      </a>
                    </div>

                    <div className="d-flex mb-2 footer-address-center">
                      <i className="fa-solid fa-location-dot fa-lg me-2 mt-1"></i>
                      <span className="fw-bold footer-font-size footer-office-indent">
                        Kantor Operasional
                      </span>
                    </div>
                    <div className="d-flex footer-address-center">
                      <a
                        href="https://goo.gl/maps/BYfWparehGeqP8kMA"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-decoration-none footer-text-color footer-font-small footer-link-highlight footer-address-indent"
                      >
                        Jl. Pluit Kencana Raya No.136, Jakarta 14450
                      </a>
                    </div>
                    <div className="d-flex footer-address-center mb-4">
                      <a
                        href="tel:+62216691609"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-decoration-none footer-text-color footer-font-small footer-link-highlight footer-address-indent"
                      >
                        (021) 669 1609
                      </a>
                    </div>
                    {isMobile ? null : (
                      <a
                        href="https://play.google.com/store/apps/details?id=id.co.ojire.app.ojireanywhere&pcampaignid=web_share"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="/img/gplay-button.png"
                          style={{ maxWidth: "160px", width: "100%" }}
                          className=" mt-1"
                          alt=""
                        />
                      </a>
                    )}
                  </div>

                  {/* About Ojire */}
                  <div className="col-md-2 col-lg-2 col-xl-2 mx-auto my-3 product footer-font-size">
                    <h6 className="text-uppercase fw-bold mb-4 footer-title-color footer-font-size footer-nav-title-mb">
                      Tentang Ojire
                    </h6>

                    <div className="footer-nav-mb">
                      <div
                        className="text-reset text-decoration-none demo-to-home"
                        onClick={() => handleSectionNav("whyOjire")}
                      >
                        Kenapa Ojire
                      </div>
                    </div>
                    <div className="footer-nav-mb">
                      <div
                        className="text-reset text-decoration-none demo-to-home"
                        onClick={() => handleSectionNav("fiturOjire")}
                      >
                        Fitur
                      </div>
                    </div>
                    <div className="footer-nav-mb">
                      <div
                        className="text-reset text-decoration-none demo-to-home"
                        onClick={() => handleSectionNav("how")}
                      >
                        Cara Kerja
                      </div>
                    </div>
                    <div className="footer-nav-mb">
                      <div
                        className="text-reset text-decoration-none demo-to-home"
                        onClick={() => handleSectionNav("faq")}
                      >
                        FAQ
                      </div>
                    </div>
                    <div className="footer-nav-mb">
                      <a
                        href="/artikel"
                        className="text-reset text-decoration-none"
                      >
                        Artikel
                      </a>
                    </div>
                  </div>

                  {/* Panduan column */}
                  <div className="col-md-2 col-lg-2 col-xl-2 mx-auto my-3 mb-md-0 footer-font-size">
                    {/* Links */}
                    <h6 className="text-uppercase fw-bold mb-4 footer-title-color footer-font-size">
                      Panduan
                    </h6>
                    <a
                      href="/layanan"
                      className="text-decoration-none footer-nav-mb footer-text-color footer-link-highlight d-block"
                    >
                      Layanan Pengaduan
                    </a>
                    <a
                      href="https://ojire.support/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-decoration-none footer-nav-mb footer-text-color footer-link-highlight d-block"
                    >
                      Bantuan
                    </a>
                  </div>

                  {/* Pengaduan column */}
                  <div
                    className="col-md-4 col-lg-4 col-xl-4 mx-auto my-3"
                    id="licensed"
                  >
                    <div className="ms-xl-5">
                      <h6 className="text-uppercase fw-bold mb-2 footer-title-color footer-font-size">
                        Layanan Pengaduan Konsumen
                      </h6>
                      <div className="text-decoration-none footer-text-color footer-font-small pengaduan-line-height d-block">
                        PT. Ojire Teknologi Informasi <br />
                        Senin - Jumat (08.00 - 17.00 WIB) <br />
                        Email: halo@ojire.com <br />
                        Telepon: (021) 2188 0008 <br />
                        Chat Whatsapp: 0852 1561 7198
                      </div>
                      <div className="text-decoration-none fw-bold footer-text-color footer-font-small d-block mt-3 mb-2">
                        Direktorat Jendral Perlindungan Konsumen dan Tertib
                        Niaga, Kementrian Perdagangan Republik Indonesia
                      </div>
                      <div className="text-decoration-none footer-text-color footer-font-small d-block">
                        Whatsapp: 0853-1111-1010
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div className="container text-center p-4" id="copyright">
              <hr className="footer-custom-hr pb-2" />
              {isMobile ? (
                <div className="mx-auto" id="licensed">
                  <a
                    href="https://play.google.com/store/apps/details?id=id.co.ojire.app.ojireanywhere&pcampaignid=web_share"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/img/gplay-button.png"
                      style={{ maxWidth: "200px", width: "100%" }}
                      className=" mt-4 mb-4"
                      alt="Google Play"
                    />
                  </a>
                  <img
                    src="/img/license-bi.png"
                    style={{
                      marginBottom: "12px",
                      maxWidth: "200px",
                      width: "100%",
                    }}
                    alt=""
                  />
                  <div className="d-flex">
                    <img
                      src="/img/kominfo.png"
                      className="license-logo"
                      style={{
                        marginRight: "12px",
                        maxWidth: "88px",
                      }}
                      alt=""
                    />
                    <div className="d-flex flex-column">
                      <img
                        src="/img/aspi.png"
                        className="license-logo mb-2"
                        alt=""
                      />
                      <img
                        src="/img/pci-dss.png"
                        className="license-logo mb-auto"
                        alt=""
                      />
                    </div>
                  </div>
                  <img
                    src="/img/apgi.png"
                    className="mt-3"
                    style={{
                      maxWidth: "100px",
                      width: "50%",
                    }}
                    alt=""
                  />
                  <div className="d-flex justify-content-center mx-5 my-5">
                    © Copyright PT. Ojire Teknologi Informasi 2025
                  </div>
                </div>
              ) : (
                <div className="d-flex justify-content-between mb-5">
                  <div className="d-flex gap-3">
                    <img
                      src="/img/license-bi.png"
                      className="footer-license-bi"
                      alt="BI"
                    />
                    <img
                      src="/img/kominfo.png"
                      className="footer-license-kominfo"
                      alt="Kominfo"
                    />
                    <img
                      src="/img/pci-dss.png"
                      className="footer-license-pcidss"
                      alt="PCIDSS"
                    />
                    <img
                      src="/img/apgi.png"
                      className="footer-license-apgi"
                      alt="APGI"
                    />
                    <img
                      src="/img/aspi.png"
                      className="footer-license-aspi"
                      alt="ASPI"
                    />
                  </div>
                  <div className="footer-copyright-container">
                    © Copyright PT. Ojire Teknologi Informasi 2025
                  </div>
                </div>
              )}
            </div>
          </footer>
          {/* Footer*/}
        </div>
      </div>
    </HelmetProvider>
  );
};

export default PrototypesPage;
