import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import "../styles/styles.css";
import "../styles/customstyle.css";
import FooterStandard from "../components/FooterStandard";
import { Helmet, HelmetProvider } from "react-helmet-async";

const ServicePage = () => {
  const [isMobile, setMobile] = useState(window.innerWidth < 768);

  const updateMobileSizeStatus = () => {
    setMobile(window.innerWidth < 768);
  };

  const navigate = useNavigate();
  const handleSectionNav = (id) => {
    navigate("/");
    setTimeout(() => {
      document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMobileSizeStatus);
    return () => {
      window.removeEventListener("resize", updateMobileSizeStatus);
    };
  });

  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <meta
            name="keywords"
            content="UMKM, Payment, kasir, invoice, Merchant, toko, digital, pembayaran, QRIS"
          />
          <title>Ojire Anywhere | Pengaduan Keluhan</title>
        </Helmet>
        <div id="page-top">
          {/* Navigation */}
          <Navbar
            expand="lg"
            fixed="top"
            bg="light"
            variant="light"
            id="mainNav"
            className="py-2 align-center"
          >
            <Container className="px-3 justify-content-between">
              <Navbar.Brand href="/">
                <img
                  src="/img/Logo Ojire.png"
                  alt="Ojire Logo"
                  style={{ margin: "8px" }}
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarTogglerDemo02">
                <a
                  className="nav-toggle"
                  onClick={() =>
                    document.querySelector(".navbar-toggler").click()
                  }
                >
                  <span className="bar"></span>
                  <span className="bar"></span>
                  <span className="bar"></span>
                </a>
              </Navbar.Toggle>
              <Navbar.Collapse id="navbarTogglerDemo02">
                <Nav
                  className={`navbar-nav my-2 my-lg-0 ${
                    isMobile ? "mx-4" : "mx-auto"
                  }`}
                >
                  <div
                    className="nav-link nav-layanan-pointer"
                    onClick={() => handleSectionNav("whyOjire")}
                  >
                    Kenapa Ojire
                  </div>
                  <div
                    className="nav-link nav-layanan-pointer"
                    onClick={() => handleSectionNav("fiturOjire")}
                  >
                    Fitur
                  </div>
                  <div
                    className="nav-link nav-layanan-pointer"
                    onClick={() => handleSectionNav("how")}
                  >
                    Cara Kerja
                  </div>
                  <div
                    className="nav-link nav-layanan-pointer"
                    onClick={() => handleSectionNav("faq")}
                  >
                    FAQ
                  </div>
                  <Nav.Link href="/artikel" className="nav-link">
                    <span className="nav-link-last">Artikel</span>
                  </Nav.Link>
                </Nav>
                <div className="d-flex">
                  <a href="/demo">
                    <Button variant="primary">Hubungi Kami &gt;</Button>
                  </a>
                </div>
              </Navbar.Collapse>
            </Container>
          </Navbar>

          <section className="page-section" id="prosesPengaduan">
            <div className="container">
              <h1 className="mb-3">Proses Pengaduan</h1>
              <p className="text-justify">
                Di Ojire, kami berkomitmen untuk memberikan layanan terbaik
                kepada kamu setiap saat. Dalam situasi kamu tidak puas atas
                layanan kami, dan atau ada yang kurang jelas silakan hubungi
                kami sesegera mungkin.
              </p>

              <h4 className="mt-4">Layanan Pengaduan Konsumen:</h4>
              <p className="my-2">
                PT. Ojire Teknologi Informasi | ojire.technology | Aplikasi
                Ojire Anywhere | +62 21 2188 0008 | halo@ojire.com
              </p>
              <p className="mb-1 fw-semibold">
                Direktorat Jenderal Perlindungan Konsumen dan Tertib Niaga
                Kementrian Perdagangan Republik Indonesia
              </p>
              <p className="mb-0">
                <img src="/img/WhatsApp.svg" width="20px" alt="whatsapp icon" />
                <a
                  href="https://wa.me/+6285311111010"
                  className="text-reset"
                  target="_blank"
                >
                  Ditjen PKTN: +62853 1111 1010
                </a>
              </p>

              <h4 className="mt-4">
                Bagaimana <i>Help Center</i> Ojire akan membantu kamu:
              </h4>
              <ol className="mt-2">
                <li>
                  <strong>Penyelesaian Secara Cepat</strong>
                  <p className="text-justify">
                    Kami akan berusaha menyelesaikan pengaduan kamu secepat
                    mungkin sesuai dengan SLA kami.
                  </p>
                </li>
                <li>
                  <strong>Konfirmasi</strong>
                  <p className="text-justify">
                    Jika pengaduan kamu tidak dapat terselesaikan secara instan,
                    kami akan mengirimkan konfirmasi tertulis <b>via email</b>{" "}
                    dalam waktu 3 hari kerja setelah hari pengaduan kamu kami
                    terima. Dan kami akan memberitahukan kapan kami akan dapat
                    menyelesaikannya.
                  </p>
                </li>
                <li>
                  <strong>Pembaharuan Perkembangan</strong>
                  <p className="text-justify">
                    Dalam kebanyakan kasus, pengaduan kamu akan diselesaikan
                    dalam waktu 15 hari kerja. Jika kami tidak dapat
                    menyelesaikan pengaduanmu dalam jangka waktu tersebut, kamu
                    akan menerima email yang menjelaskan alasannya dan kapan
                    kamu akan mendapatkan penyelesaiannya (tidak lebih dari 35
                    hari kerja setelah hari kami pertama kali menerima pengaduan
                    kamu).
                  </p>
                </li>
                <li>
                  <strong>Tanggapan Akhir</strong>
                  <p className="text-justify">
                    Ketika pengaduan kamu telah diselesaikan, kamu akan menerima
                    tanggapan akhir dari Ojire.
                  </p>
                </li>
                <li>
                  <strong>Jika Kamu Tetap Tidak Puas</strong>
                  <p className="text-justify">
                    Tujuan kami adalah menyelesaikan semua pengaduan secara
                    netral dan cepat. Namun, jika kamu tidak puas dengan
                    resolusi yang kami berikan, atau jika 35 hari kerja telah
                    berlalu sejak kamu pertama kali mengajukan pengaduan, kamu
                    memiliki hak untuk merujuk pengaduan kamu ke Direktorat
                    Jenderal Perlindungan Konsumen dan Tertib Niaga Kementrian
                    Perdagangan Republik Indonesia.
                  </p>
                </li>
              </ol>
            </div>
          </section>

          {/* Footer */}
          <FooterStandard />
          {/* Footer*/}
        </div>
      </div>
    </HelmetProvider>
  );
};

export default ServicePage;
