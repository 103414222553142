import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@fortawesome/fontawesome-free/js/all.min.js";
import "../styles/styles.css";
import "../styles/customstyle.css";
import FooterStandard from "../components/FooterStandard";
import BlogCard from "../components/BlogCard";
import { Helmet, HelmetProvider } from "react-helmet-async";
import he from "he";
import DOMPurify from "dompurify";

const ArticlePage = () => {
  const { slug } = useParams();
  const [articleContent, setArticleContent] = useState(null);
  const [articleCards, setArticleCards] = useState([]);
  const [scrollToTopVisible, setScrollToTopVisible] = useState(false);
  const [whatsappFloatingVisible, setWhatsappFloatingVisible] = useState(false);
  const [isMobile, setMobile] = useState(window.innerWidth < 768);
  const [currentUrl, setCurrentUrl] = useState("");
  const [encodedTitle, setEncodedTitle] = useState("");
  const [encodedUrl, setEncodedUrl] = useState("");
  const [textSections, setTextSections] = useState([]);
  const [cardsPerPage, setCardsPerPage] = useState(
    window.innerWidth < 768 ? 2 : 4
  );

  // Default values
  const defaultTitle = "Untitled Article";
  const defaultSlug = "no-slug";
  // const defaultCategory = "Uncategorized";
  // const defaultImgUrl = "http://localhost:1337/default-img.png";
  const defaultImgUrl = `${process.env.REACT_APP_BLOG_RESOURCES_URL}/v1/documents/file/ojire/blog/image/default_image.png`;
  const defaultBody = "Default text.";

  const updateMobileSizeStatus = () => {
    setMobile(window.innerWidth < 768);

    if (isMobile) {
      setCardsPerPage(2);
    } else {
      setCardsPerPage(4);
    }
  };

  const navigate = useNavigate();
  const handleSectionNav = (id) => {
    navigate("/");
    setTimeout(() => {
      document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  // Transforms date from ISO 8601 to 'DD MMM YYYY'
  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const options = { day: "numeric", month: "short", year: "numeric" };
    return date.toLocaleDateString("id-ID", options).replace(/\s/g, " ");
  };

  // Scroll to handle floating button that anchors to page top
  const handleFloatingScroll = () => {
    const scrollTop = window.scrollY;

    if (scrollTop > 1000) {
      setWhatsappFloatingVisible(true);
      setScrollToTopVisible(true);
    } else if (scrollTop > 200) {
      setWhatsappFloatingVisible(true);
      setScrollToTopVisible(false);
    } else {
      setWhatsappFloatingVisible(false);
      setScrollToTopVisible(false);
    }
  };

  // Handle "scroll to top" button click
  const scrollToTopBtn = document.getElementById("scrollToTopBtn");
  if (scrollToTopBtn) {
    scrollToTopBtn.addEventListener("click", () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    });
  }

  // Hide or show navbar based on scroll position
  let lastScrollTop = 0;
  const limitHeight = 100;

  const handleMainNavScroll = () => {
    const st = window.scrollY;

    if (st > lastScrollTop && st > limitHeight) {
      document.getElementById("mainNav").classList.add("hidden");
    } else {
      document.getElementById("mainNav").classList.remove("hidden");
    }

    lastScrollTop = st;
  };

  // Main useEffect
  useEffect(() => {
    setCurrentUrl(window.location.href);
    window.addEventListener("resize", updateMobileSizeStatus);
    window.addEventListener("scroll", handleFloatingScroll);
    window.addEventListener("scroll", handleMainNavScroll);
    return () => {
      window.removeEventListener("resize", updateMobileSizeStatus);
      window.removeEventListener("scroll", handleFloatingScroll);
      window.removeEventListener("scroll", handleMainNavScroll);
      if (scrollToTopBtn) {
        scrollToTopBtn.removeEventListener("click", () => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
      }
    };
  });

  // useEffect for encoding URL
  useEffect(() => {
    setEncodedUrl(encodeURIComponent(currentUrl));
  }, [currentUrl]);

  // useEffect for article content API call only
  useEffect(() => {
    const fetchArticleData = async () => {
      try {
        // Main Article Content
        const responseContent = await fetch(
          `${process.env.REACT_APP_BLOG_BASE_URL}/blog/public/view/${slug}`
        );
        const dataContent = await responseContent.json();
        setArticleContent(dataContent.blogs); // Main article data
        setEncodedTitle(
          encodeURIComponent(dataContent.blogs.title || defaultTitle)
        ); // Article title when sharing to social media
        const unicodeArticleBody = dataContent.blogs.content || defaultBody; // Article body
        const unsanitizedBody = he.decode(unicodeArticleBody);
        const sanitizedBody = DOMPurify.sanitize(unsanitizedBody, {
          ALLOWED_TAGS: [
            "p",
            "b",
            "i",
            "em",
            "strong",
            "ul",
            "ol",
            "li",
            "h1",
            "h2",
            "h3",
            "h4",
            "h5",
            "h6",
            "img",
          ],
          ALLOWED_ATTR: ["src", "alt", "className"],
          FORBID_ATTR: ["href", "style", "on*", "onclick"],
          ADD_ATTR: {
            h2: {
              className: "article-subheading",
            },
            img: {
              className: "article-standard-img",
            },
            p: {
              className: "article-text mt-2",
            },
            li: {
              className: "article-list-title article-list-title-counter",
            },
          },
        });

        // Adding classes to HTML inserts
        function addClassToHeader2(html) {
          return html.replace(/<h2>/g, '<h2 class="article-subheading mt-4">');
        }
        function addClassToImage(html) {
          return html.replace(/<img/g, '<img class="article-standard-img" ');
        }
        function addClassToParagraph(html) {
          return html.replace(/<p>/g, '<p class="article-text">');
        }
        function addClassToList(html) {
          return html.replace(
            /<li>/g,
            '<li class="article-list-title article-list-title-counter">'
          );
        }
        function addBoldToListItem(html) {
          return html.replace(/<li>(.*?):/g, "<li><b>$1:</b>");
        }

        const articleBody = addClassToHeader2(
          addClassToImage(
            addClassToParagraph(
              addClassToList(addBoldToListItem(sanitizedBody))
            )
          )
        );

        // const articleBody = addClassToHeader2(
        //   addClassToParagraph(addClassToList(sanitizedBody))
        // );

        // setTextSections(articleBody.split("\n\n"));
        setTextSections(articleBody);

        // Data for articles of same category
        // const categoryName = "Bisnis & Keuangan";
        // dataContent.data[0].category.name || defaultCategory;
        const responseCards = await fetch(
          `${process.env.REACT_APP_BLOG_BASE_URL}/blog/public/list`
        );
        const rawDataCards = await responseCards.json();
        const dataCards = rawDataCards.blogs.slice(0, cardsPerPage + 1);
        const filteredArticles = dataCards
          .filter((article) => article.slug !== slug)
          .slice(0, cardsPerPage);

        setArticleCards(filteredArticles);
      } catch (error) {
        console.error("Error fetching article:", error);
      }
    };

    fetchArticleData();
  }, [slug]);

  return articleContent && articleCards ? (
    <HelmetProvider>
      <div>
        <Helmet>
          <meta
            name="keywords"
            content="UMKM, Payment, kasir, invoice, Merchant, toko, digital, pembayaran, QRIS"
          />
          <title>Ojire Anywhere | {articleContent.title}</title>
        </Helmet>
        <div id="page-top">
          {/* Navigation */}
          <Navbar
            expand="lg"
            fixed="top"
            bg="light"
            variant="light"
            id="mainNav"
            className="py-2 align-center"
          >
            <Container className="px-3 justify-content-between">
              <Navbar.Brand href="/">
                <img
                  src="/img/Logo Ojire.png"
                  alt="Ojire Logo"
                  style={{ margin: "8px" }}
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarTogglerDemo02">
                <a
                  className="nav-toggle"
                  onClick={() =>
                    document.querySelector(".navbar-toggler").click()
                  }
                >
                  <span className="bar"></span>
                  <span className="bar"></span>
                  <span className="bar"></span>
                </a>
              </Navbar.Toggle>
              <Navbar.Collapse id="navbarTogglerDemo02">
                <Nav
                  className={`navbar-nav my-2 my-lg-0 ${
                    isMobile ? "mx-4" : "mx-auto"
                  }`}
                >
                  <div
                    className="nav-link nav-layanan-pointer"
                    onClick={() => handleSectionNav("whyOjire")}
                  >
                    Kenapa Ojire
                  </div>
                  <div
                    className="nav-link nav-layanan-pointer"
                    onClick={() => handleSectionNav("fiturOjire")}
                  >
                    Fitur
                  </div>
                  <div
                    className="nav-link nav-layanan-pointer"
                    onClick={() => handleSectionNav("how")}
                  >
                    Cara Kerja
                  </div>
                  <div
                    className="nav-link nav-layanan-pointer"
                    onClick={() => handleSectionNav("faq")}
                  >
                    FAQ
                  </div>
                  <Nav.Link href="/artikel" className="active nav-link">
                    <span className="nav-link-last">Artikel</span>
                  </Nav.Link>
                </Nav>
                <div className="d-flex">
                  <a href="/demo">
                    <Button variant="primary">Hubungi Kami &gt;</Button>
                  </a>
                </div>
              </Navbar.Collapse>
            </Container>
          </Navbar>

          {/* Scroll-to-top and WhatsApp float*/}
          <div className="floating-blog">
            <img
              src="/img/PNG/ArrowCircleUp.png"
              id="scrollToTopBtn"
              className={
                scrollToTopVisible
                  ? "scroll-to-top-btn-fade-in"
                  : "scroll-to-top-btn-fade-out"
              }
              alt=""
            />
            <a
              className={`btn ${
                whatsappFloatingVisible
                  ? "btn-whatsapp-fade-in"
                  : "btn-whatsapp-fade-out"
              }`}
              id="whatsappChat"
              href="https://wa.me/6285215617198"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa-brands fa-whatsapp fa-2xl"></i>
            </a>
          </div>

          {/* Content Section*/}
          <section
            className="page-section article-content-padding"
            id="blogArticle"
          >
            <div className="container">
              {/* Directory */}
              <div>
                <img
                  src="/img/PNG/home-icon.png"
                  className={`directory-home-icon me-3 ${
                    isMobile ? "mb-2" : "mb-1"
                  }`}
                  height="22"
                  alt=""
                />
                <span className="me-3">
                  <a href="/" className="text-decoration-none directory-text">
                    Beranda
                  </a>
                </span>
                <span className="me-3 directory-arrow">&gt;</span>
                <span className="me-3">
                  <a
                    href="/artikel"
                    className="text-decoration-none directory-text"
                  >
                    Artikel
                  </a>
                </span>
                <span className="me-3 directory-arrow">&gt;</span>
                <span>
                  <a
                    href="/artikel"
                    className="text-decoration-none directory-text directory-text-limit directory-active-hover"
                  >
                    {articleContent.title || defaultTitle}
                  </a>
                </span>
              </div>

              {/* Article Body */}
              <div className="row mt-5">
                {/* Sticky Sidebar */}
                <div className="col-md-3">
                  <div className="sticky-sidebar">
                    <h4 className="article-sidebar-title mt-2">Daftar Isi</h4>
                    <hr className="article-sidebar-divider mt-3" />
                    <div>
                      {/* {article_api_call.subheadings.map((subheading) => (
                        <a
                          key={subheading.id}
                          href={`#${subheading.id}`}
                          onClick={(e) => {
                            e.preventDefault();
                            const element = document.getElementById(subheading.id);
                            const offset = 100;
                            const bodyRect =
                              document.body.getBoundingClientRect().top;
                            const elementRect = element.getBoundingClientRect().top;
                            const offsetPosition = elementRect - bodyRect - offset;

                            window.scrollTo({
                              top: offsetPosition,
                              behavior: "smooth",
                            });
                          }}
                        >
                          <div className="article-sidebar-items mb-3">
                            {subheading.title}
                          </div>
                        </a>
                      ))} */}
                      {/* {textSections.map((section, index) => {
                      const isSubheading = /^##\s(?!#)/.test(section);

                      if (isSubheading) {
                        return (
                          <a
                            key={index}
                            href={`#${section.replace(/^##\s/, "")}`}
                          >
                            <div className="article-sidebar-items mb-3">
                              {section.replace(/^##\s/, "")}
                            </div>
                          </a>
                        );
                      } else {
                        return null;
                      }
                    })} */}
                      {textSections
                        .match(
                          /<h2 class="article-subheading mt-4">([^<]+)<\/h2>/g
                        )
                        ?.map((subheading) => {
                          const extractedText = subheading.match(
                            /<h2 class="article-subheading mt-4">([^<]+)<\/h2>/
                          )[1];
                          return (
                            <div
                              className="article-sidebar-items mb-3"
                              key={extractedText}
                            >
                              {extractedText}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
                {/* Main Content */}
                <div className="col-md-8">
                  <article>
                    {/* Title and date */}
                    <h1 className="article-title">
                      {articleContent.title || defaultTitle}
                    </h1>
                    <p className="article-date">
                      Tayang {formatDate(articleContent.created_at)}
                    </p>

                    {/* Sharing links (mobile only)*/}
                    <div className="article-sharing-container-mobile">
                      <span className="article-sharing-title-mobile me-3">
                        Share
                      </span>
                      <a
                        href={`https://api.whatsapp.com/send?text=${encodedTitle}%20${encodedUrl}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="article-sharing-backing me-3"
                      >
                        <img
                          src="/img/PNG/blog-share-wa-icon.png"
                          alt="WhatsApp Share"
                          className="article-sharing-icon"
                        />
                      </a>
                      <a
                        href={`https://twitter.com/intent/tweet?text=${encodedTitle}&url=${encodedUrl}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="article-sharing-backing me-3"
                      >
                        <img
                          src="/img/PNG/blog-share-x-icon.png"
                          alt="X Share"
                          className="article-sharing-icon"
                        />
                      </a>
                      <a
                        href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=${encodedTitle}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="article-sharing-backing me-3"
                      >
                        <img
                          src="/img/PNG/blog-share-li-icon.png"
                          alt="LinkedIn Share"
                          className="article-sharing-icon"
                        />
                      </a>
                      <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="article-sharing-backing me-3"
                      >
                        <img
                          src="/img/PNG/blog-share-fb-icon.png"
                          alt="Facebook Share"
                          className="article-sharing-icon"
                        />
                      </a>
                    </div>

                    {/* Main article image */}
                    <img
                      src={
                        // articleContent.img
                        //   ? //`http://localhost:1337${articleContent.main_img.url}`
                        //     `http://strapi-test.septiantest.web.id${articleContent.main_img.url}`
                        //   : defaultImgUrl
                        articleContent.image || defaultImgUrl
                      }
                      alt="Article Main Image"
                      className="article-main-img mt-3"
                    />

                    {/* Render rich text for article content */}
                    <div className="mt-5">
                      {/* {textSections.map((section, index) => {
                      const isSubheading = /^##\s(?!#)/.test(section);
                      const isListTitle = /^\d+\.\s/.test(section);
                      const isListText = /^###\s/.test(section);
                      const isImage = /!\[.*?\]\((.*?)\)/.test(section);

                      if (isSubheading) {
                        return (
                          <h2
                            key={index}
                            id={section.replace(/^##\s/, "")}
                            className="article-subheading"
                          >
                            {section.replace(/^##\s/, "")}
                          </h2>
                        );
                      } else if (isListTitle) {
                        return (
                          <h4
                            key={index}
                            className="article-list-title article-list-title-counter"
                          >
                            {section}
                          </h4>
                        );
                      } else if (isListText) {
                        return (
                          <p key={index} className="article-list-text mt-2">
                            {section.replace(/^###\s/, "")}
                          </p>
                        );
                      } else if (isImage) {
                        const imgSrc = section.match(/!\[.*?\]\((.*?)\)/)[1];
                        return (
                          <img
                            key={index}
                            src={imgSrc}
                            alt=""
                            className="article-standard-img"
                          />
                        );
                      } else {
                        return (
                          <p key={index} className="article-text mt-2">
                            {section}
                          </p>
                        );
                      }
                    })} */}
                      <div dangerouslySetInnerHTML={{ __html: textSections }} />
                    </div>
                  </article>
                </div>
                {/* Sharing links (for desktop and tablet)*/}
                <div className="col-md-1">
                  <div className="sticky-sidebar d-flex flex-column align-items-center">
                    <div className="article-sharing-title">Share</div>
                    <div className="mt-3">
                      <a
                        href={`https://api.whatsapp.com/send?text=${encodedTitle}%20${encodedUrl}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="article-sharing-backing mb-3"
                      >
                        <img
                          src="/img/PNG/blog-share-wa-icon.png"
                          alt="WhatsApp Share"
                          className="article-sharing-icon"
                        />
                      </a>
                      <a
                        href={`https://twitter.com/intent/tweet?text=${encodedTitle}&url=${encodedUrl}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="article-sharing-backing mb-3"
                      >
                        <img
                          src="/img/PNG/blog-share-x-icon.png"
                          alt="X Share"
                          className="article-sharing-icon"
                        />
                      </a>
                      <a
                        href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=${encodedTitle}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="article-sharing-backing mb-3"
                      >
                        <img
                          src="/img/PNG/blog-share-li-icon.png"
                          alt="LinkedIn Share"
                          className="article-sharing-icon"
                        />
                      </a>
                      <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="article-sharing-backing mb-3"
                      >
                        <img
                          src="/img/PNG/blog-share-fb-icon.png"
                          alt="Facebook Share"
                          className="article-sharing-icon"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Content Section*/}

          {/* Related Articles Section */}
          <div className="container article-suggestions-padding">
            <hr className="article-suggestions-divider" />
            <div className="article-suggestions-spacer mt-5 mb-4">
              <span className="article-suggestions-title">Artikel Terkait</span>
              <span className="article-suggestions-more">
                <Link className="article-suggestions-more-text" to="/artikel">
                  Lihat semua
                  <img
                    src="/img/PNG/arrow-right.png"
                    alt="Arrow"
                    className="article-suggestions-arrow"
                  />
                </Link>
              </span>
            </div>
            <div className="blog-card-gallery">
              {articleCards.map((article) => (
                <BlogCard
                  key={article.slug}
                  title={article.title || defaultTitle}
                  slug={article.slug || defaultSlug}
                  category={
                    // articleContent.category
                    //   ? articleContent.category.name
                    //   : defaultCategory
                    "Bisnis & Keuangan"
                  }
                  date={article.created_at}
                  main_img={
                    // article.main_img
                    //   ? // `http://localhost:1337${article.main_img.url}`
                    //     `http://strapi-test.septiantest.web.id${article.main_img.url}`
                    //   : defaultImgUrl
                    article.image || defaultImgUrl
                  }
                />
              ))}
            </div>
          </div>
          {/* Related Articles Section */}

          {/* Footer */}
          <FooterStandard />
          {/* Footer*/}
        </div>
      </div>
    </HelmetProvider>
  ) : null;
};

export default ArticlePage;
