import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@fortawesome/fontawesome-free/js/all.min.js";
import "../styles/styles.css";
import "../styles/customstyle.css";

const FooterStandard = () => {
  const [isMobile, setMobile] = useState(window.innerWidth < 768);

  const updateMobileSizeStatus = () => {
    setMobile(window.innerWidth < 768);
  };

  const navigate = useNavigate();
  const handleSectionNav = (id) => {
    navigate("/");
    setTimeout(() => {
      document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMobileSizeStatus);
    return () => {
      window.removeEventListener("resize", updateMobileSizeStatus);
    };
  });

  return (
    <footer className="text-center footer-text-color footer-font-size">
      <section className="pt-2">
        <div className="container text-center text-md-start mt-4">
          {/* Grid row */}
          <div className="row">
            {isMobile ? (
              <div>
                <a className="" href="#page-top">
                  <img
                    src="/img/Logo Ojire.png"
                    className="mb-5"
                    height="70"
                    alt=""
                  />
                </a>
                <div className="gap-3 d-flex justify-content-center mb-5">
                  <a
                    href="https://www.facebook.com/Ojire.ID"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="align-content-center text-reset"
                  >
                    <img
                      src="/img/PNG/facebook.png"
                      className=""
                      width="32"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://id.linkedin.com/company/ojire"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="align-content-center text-reset"
                  >
                    <img
                      src="/img/PNG/linkedin.png"
                      className=""
                      width="32"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/ojireofficial/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="align-content-center text-reset"
                  >
                    <img
                      src="/img/PNG/instagram.png"
                      className=""
                      width="32"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/@Ojireofficial"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="align-content-center text-reset"
                  >
                    <img
                      src="/img/PNG/youtube.png"
                      className=""
                      width="32"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://www.tiktok.com/@ojireofficial?is_from_webapp=1&sender_device=pc"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="align-content-center text-reset"
                  >
                    <img
                      src="/img/PNG/tiktok.png"
                      className=""
                      width="32"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-between mb-3">
                <a className="" href="#page-top">
                  <img
                    src="/img/Logo Ojire.png"
                    className=""
                    height="44"
                    alt=""
                  />
                </a>
                <div className="gap-3 d-flex">
                  <a
                    href="https://www.facebook.com/Ojire.ID"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="align-content-center text-reset"
                  >
                    <img
                      src="/img/PNG/facebook.png"
                      className=""
                      width="32"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://id.linkedin.com/company/ojire"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="align-content-center text-reset"
                  >
                    <img
                      src="/img/PNG/linkedin.png"
                      className=""
                      width="32"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/ojireofficial/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="align-content-center text-reset"
                  >
                    <img
                      src="/img/PNG/instagram.png"
                      className=""
                      width="32"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/@Ojireofficial"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="align-content-center text-reset"
                  >
                    <img
                      src="/img/PNG/youtube.png"
                      className=""
                      width="32"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://www.tiktok.com/@ojireofficial?is_from_webapp=1&sender_device=pc"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="align-content-center text-reset"
                  >
                    <img
                      src="/img/PNG/tiktok.png"
                      className=""
                      width="32"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            )}
            {/* Offices column */}
            <div className="col-md-4 col-lg-4 col-xl-4 px-sm-2 px-5 mx-auto my-3">
              {/* Content */}
              <div className="d-flex mb-2 footer-address-center">
                <i className="fa-solid fa-location-dot fa-lg me-2 mt-1"></i>
                <span className="fw-bold footer-font-size footer-office-indent">
                  Kantor Pusat
                </span>
              </div>
              <div className="d-flex footer-address-center">
                <a
                  href="https://goo.gl/maps/7P7Bt21Wqhf7xP46A"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none footer-text-color footer-font-small footer-link-highlight footer-address-indent"
                >
                  Gedung One Pacific Place Lt. 15, Suite 15-01 <br />
                  Jl. Jendral Sudirman Kav. 52-53, Jakarta 12190
                </a>
              </div>
              <div className="d-flex footer-address-center mb-4">
                <a
                  href="tel:+622125502533"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none footer-text-color footer-font-small footer-link-highlight footer-address-indent"
                >
                  (021) 2550 2533
                </a>
              </div>
              <div className="d-flex mb-2 footer-address-center">
                <i className="fa-solid fa-location-dot fa-lg me-2 mt-1"></i>
                <span className="fw-bold footer-font-size footer-office-indent">
                  Kantor Operasional
                </span>
              </div>
              <div className="d-flex footer-address-center">
                <a
                  href="https://goo.gl/maps/BYfWparehGeqP8kMA"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none footer-text-color footer-font-small footer-link-highlight footer-address-indent"
                >
                  Jl. Pluit Kencana Raya No. 136, Jakarta 11450
                </a>
              </div>
              <div className="d-flex footer-address-center mb-4">
                <a
                  href="tel:+62216691609"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none footer-text-color footer-font-small footer-link-highlight footer-address-indent"
                >
                  (021) 669 1609
                </a>
              </div>
              {isMobile ? null : (
                <a
                  href="https://play.google.com/store/apps/details?id=id.co.ojire.app.ojireanywhere&pcampaignid=web_share"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/img/gplay-button.png"
                    style={{ maxWidth: "160px", width: "100%" }}
                    className=" mt-1"
                    alt=""
                  />
                </a>
              )}
            </div>

            {/* About Ojire */}
            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto my-3 product footer-font-size">
              <h6 className="text-uppercase fw-bold mb-4 footer-title-color footer-font-size footer-nav-title-mb">
                Tentang Ojire
              </h6>

              <div className="footer-nav-mb">
                <div
                  className="text-reset text-decoration-none demo-to-home"
                  onClick={() => handleSectionNav("whyOjire")}
                >
                  Kenapa Ojire
                </div>
              </div>
              <div className="footer-nav-mb">
                <div
                  className="text-reset text-decoration-none demo-to-home"
                  onClick={() => handleSectionNav("fiturOjire")}
                >
                  Fitur
                </div>
              </div>
              <div className="footer-nav-mb">
                <div
                  className="text-reset text-decoration-none demo-to-home"
                  onClick={() => handleSectionNav("how")}
                >
                  Cara Kerja
                </div>
              </div>
              <div className="footer-nav-mb">
                <div
                  className="text-reset text-decoration-none demo-to-home"
                  onClick={() => handleSectionNav("faq")}
                >
                  FAQ
                </div>
              </div>
              <div className="footer-nav-mb">
                <a href="/artikel" className="text-reset text-decoration-none">
                  Artikel
                </a>
              </div>
            </div>

            {/* Panduan column */}
            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto my-3 mb-md-0 footer-font-size">
              {/* Links */}
              <h6 className="text-uppercase fw-bold mb-4 footer-title-color footer-font-size">
                Panduan
              </h6>
              <a
                href="/layanan"
                className="text-decoration-none footer-nav-mb footer-text-color footer-link-highlight d-block"
              >
                Layanan Pengaduan
              </a>
              <a
                href="https://ojire.support/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none footer-nav-mb footer-text-color footer-link-highlight d-block"
              >
                Bantuan
              </a>
            </div>

            {/* Pengaduan column */}
            <div
              className="col-md-4 col-lg-4 col-xl-4 mx-auto my-3"
              id="licensed"
            >
              <div className="ms-xl-5">
                <h6 className="text-uppercase fw-bold mb-2 footer-title-color footer-font-size">
                  Layanan Pengaduan Konsumen
                </h6>
                <div className="text-decoration-none footer-text-color footer-font-small pengaduan-line-height d-block">
                  PT. Ojire Teknologi Informasi <br />
                  Senin - Jumat (08.00 - 17.00 WIB) <br />
                  Email: halo@ojire.com <br />
                  Telepon: (021) 2188 0008 <br />
                  Chat Whatsapp: 0852 1561 7198
                </div>
                <div className="text-decoration-none fw-bold footer-text-color footer-font-small d-block mt-3 mb-2">
                  Direktorat Jendral Perlindungan Konsumen dan Tertib Niaga,
                  Kementrian Perdagangan Republik Indonesia
                </div>
                <div className="text-decoration-none footer-text-color footer-font-small d-block">
                  Whatsapp: 0853-1111-1010
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container text-center py-4" id="copyright">
        <hr className="footer-custom-hr pb-2" />
        {isMobile ? (
          <div className="mx-auto" id="licensed">
            <a
              href="https://play.google.com/store/apps/details?id=id.co.ojire.app.ojireanywhere&pcampaignid=web_share"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/img/gplay-button.png"
                style={{ maxWidth: "200px", width: "100%" }}
                className=" mt-4 mb-4"
                alt="Google Play"
              />
            </a>
            <img
              src="/img/license-bi.png"
              style={{
                marginBottom: "12px",
                maxWidth: "200px",
                width: "100%",
              }}
              alt=""
            />
            <div className="d-flex">
              <img
                src="/img/kominfo.png"
                className="license-logo"
                style={{
                  marginRight: "12px",
                  maxWidth: "88px",
                }}
                alt=""
              />
              <div className="d-flex flex-column">
                <img src="/img/aspi.png" className="license-logo mb-2" alt="" />
                <img
                  src="/img/pci-dss.png"
                  className="license-logo mb-auto"
                  alt=""
                />
              </div>
            </div>
            <img
              src="/img/apgi.png"
              className="mt-3"
              style={{
                maxWidth: "100px",
                width: "50%",
              }}
              alt=""
            />
            <div className="d-flex justify-content-center mx-5 my-5">
              © Copyright PT. Ojire Teknologi Informasi 2025
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-between mb-5">
            <div className="d-flex gap-3">
              <img
                src="/img/license-bi.png"
                className="footer-license-bi"
                alt="BI"
              />
              <img
                src="/img/kominfo.png"
                className="footer-license-kominfo"
                alt="Kominfo"
              />
              <img
                src="/img/pci-dss.png"
                className="footer-license-pcidss"
                alt="PCIDSS"
              />
              <img
                src="/img/apgi.png"
                className="footer-license-apgi"
                alt="APGI"
              />
              <img
                src="/img/aspi.png"
                className="footer-license-aspi"
                alt="ASPI"
              />
            </div>
            <div className="footer-copyright-container">
              © Copyright PT. Ojire Teknologi Informasi 2025
            </div>
          </div>
        )}
      </div>
    </footer>
  );
};

export default FooterStandard;
