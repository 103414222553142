import React, { useState } from "react";
import { Link } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@fortawesome/fontawesome-free/js/all.min.js";
import "../styles/styles.css";
import "../styles/customstyle.css";
import FooterStandard from "../components/FooterStandard";
import { Helmet, HelmetProvider } from "react-helmet-async";

const DemoPage = () => {
  // Handling mail sending
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");

  const handleSendEmail = () => {
    const subject = "Demo Request";
    const body = `Nama PIC: ${name}%0D%0AEmail: ${email}%0D%0ANomor Hp PIC: ${phone}%0D%0APesan: ${message}`;
    const mailtoLink = `mailto:halo@ojire.com?subject=${subject}&body=${body}`;

    window.location.href = mailtoLink;
  };

  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <meta
            name="keywords"
            content="UMKM, Payment, kasir, invoice, Merchant, toko, digital, pembayaran, QRIS"
          />
          <title>Ojire Anywhere | Jadwalkan Demo</title>
        </Helmet>
        <div id="page-top">
          {/* Header */}
          <section className="my-5 mt-4 position-relative" id="demo">
            <div className="container">
              <div className="row justify-content-center  px-4">
                <div className="col-12" id="bannerDemo">
                  <div className="row justify-content-center">
                    <div className="col-md-10 col-sm-12 text-center">
                      <div className="row">
                        <div className="col-lg-8 text-start">
                          <h5 className="fw-bold mb-3">Jadwalkan Demo</h5>
                          <p className="mb-1">
                            Ketahui informasi lebih lanjut untuk
                            #BikinUsahaNaikKelas
                          </p>
                          <hr className="bg-white" />
                          <ul className="list-unstyled">
                            <li>
                              <img
                                src="/img/PNG/SealCheck.png"
                                className="icon-section mx-2"
                                alt=""
                              />{" "}
                              GRATIS melihat produk kami tanpa komitmen.
                            </li>
                            <li>
                              <img
                                src="/img/PNG/SealCheck.png"
                                className="icon-section mx-2"
                                alt=""
                              />{" "}
                              GRATIS konsultasi seputar kebutuhan digitalisasi
                              kasir toko kamu untuk pencapaian bisnis.
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-4">
                          <img
                            src="/img/demo.jpg"
                            alt="Ojire Demo"
                            className="img-fluid"
                            id="demoImage"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8 col-sm-10">
                  <Link className="d-block my-5 text-decoration-none" to="/">
                    <i className="fa-solid fa-circle-arrow-left fa-xl me-2"></i>
                    Kembali ke beranda
                  </Link>
                  <form id="contactForm">
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">
                        Nama PIC <span style={{ color: "#dc3545" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Contoh: John Doe"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Email bisnis <span style={{ color: "#dc3545" }}>*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Contoh: John@ojire.technology"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="message" className="form-label">
                        Tulis pertanyaan mu di sini{" "}
                        <span style={{ color: "#dc3545" }}>*</span>
                      </label>
                      <textarea
                        className="form-control"
                        id="message"
                        rows="3"
                        placeholder="Contoh: Saya tertarik untuk mendaftar, bisakah membantu saya?"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                      ></textarea>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="phone" className="form-label">
                        Nomor Hp PIC <span style={{ color: "#dc3545" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="phone"
                        placeholder="Contoh: 0812 2121 1000 (No Whatsapp)"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                      />
                    </div>
                    <div className="text-center">
                      <button
                        type="button"
                        className="btn btn-primary w-100 mt-3"
                        id="sendEmailButton"
                        onClick={handleSendEmail}
                      >
                        Kirim
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>

          {/* Footer */}
          <FooterStandard />
          {/* Footer*/}
        </div>
      </div>
    </HelmetProvider>
  );
};

export default DemoPage;
