import React, { useEffect, useState } from "react";
import AOS from "aos";
// import SimpleLightbox from "simplelightbox";
// import "simplelightbox/dist/simple-lightbox.min.css";
import "aos/dist/aos.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@fortawesome/fontawesome-free/js/all.min.js";
import { Navbar, Nav, Container, Button, Accordion } from "react-bootstrap";
import ScrollspyNav from "react-scrollspy-nav";
import "../styles/styles.css";
import "../styles/customstyle.css";
import { Helmet, HelmetProvider } from "react-helmet-async";

const MainPage = () => {
  const [scrollToTopVisible, setScrollToTopVisible] = useState(false);
  const [whatsappFloatingVisible, setWhatsappFloatingVisible] = useState(false);
  const [bottomBarVisible, setBottomBarVisible] = useState(false);
  const [isMobile, setMobile] = useState(window.innerWidth < 768);

  const updateMobileSizeStatus = () => {
    setMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    AOS.init();

    // Function to handle AOS attributes based on window width
    const checkWidth = () => {
      const element = document.getElementById("heroImage");
      if (window.innerWidth <= 426) {
        element.removeAttribute("data-aos");
        element.removeAttribute("data-aos-duration");
      } else {
        element.setAttribute("data-aos", "fade-up");
        element.setAttribute("data-aos-duration", "1000");
      }
    };

    // Navbar shrink function
    const navbarShrink = () => {
      const navbarCollapsible = document.getElementById("mainNav");
      if (!navbarCollapsible) return;
      if (window.scrollY === 0) {
        navbarCollapsible.classList.remove("navbar-shrink");
      } else {
        navbarCollapsible.classList.add("navbar-shrink");
      }
    };

    // // Initialize SimpleLightbox
    // new SimpleLightbox({
    //   elements: "#portfolio a.portfolio-box",
    // });

    // Shrink the navbar
    navbarShrink();
    window.addEventListener("scroll", navbarShrink);

    // Activate Bootstrap scrollspy
    const mainNav = document.querySelector("#mainNav");
    if (mainNav) {
      new ScrollspyNav(document.body, {
        target: "#mainNav",
        rootMargin: "0px 0px -40%",
      });
    }

    // Hamburger menu toggle
    // const navToggle = document.querySelector(".nav-toggle");
    // const bars = document.querySelectorAll(".bar");
    // const toggleHamburger = () => {
    //   bars.forEach((bar) => bar.classList.toggle("x"));
    // };

    // if (navToggle) {
    //   navToggle.addEventListener("click", toggleHamburger);
    // } else {
    //   console.log("navToggle element not found");
    // }

    // Scroll to handle floating button that anchors to page top
    const handleFloatingScroll = () => {
      const scrollTop = window.scrollY;

      if (scrollTop > 1000) {
        setWhatsappFloatingVisible(true);
        setScrollToTopVisible(true);
        if (window.innerWidth <= 426) {
          setBottomBarVisible(true);
        }
      } else if (scrollTop > 200) {
        setWhatsappFloatingVisible(true);
        setScrollToTopVisible(false);
        if (window.innerWidth <= 426) {
          setBottomBarVisible(false);
        }
      } else {
        setWhatsappFloatingVisible(false);
        setScrollToTopVisible(false);
        if (window.innerWidth <= 426) {
          setBottomBarVisible(false);
        }
      }
    };

    // Handle "scroll to top" button click
    const scrollToTopBtn = document.getElementById("scrollToTopBtn");
    if (scrollToTopBtn) {
      scrollToTopBtn.addEventListener("click", () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      });
    }

    // Scroll to hide/show navbar and handle active nav links
    let lastScrollTop = 0;
    const headerHeight = document.querySelector("header").offsetHeight;

    const handleScroll = () => {
      const st = window.scrollY;
      if (st > lastScrollTop && st > headerHeight) {
        document.getElementById("mainNav").classList.add("hidden");
      } else {
        document.getElementById("mainNav").classList.remove("hidden");
      }
      lastScrollTop = st;

      const scrollPos =
        document.documentElement.scrollTop || document.body.scrollTop;
      let isActiveSet = false;

      document.querySelectorAll(".navbar-nav .nav-link").forEach((currLink) => {
        if (currLink.getAttribute("href") === "/artikel") {
          return;
        }

        const refElement = document.querySelector(
          currLink.getAttribute("href")
        );
        if (
          refElement &&
          refElement.offsetTop <= scrollPos &&
          refElement.offsetTop + refElement.offsetHeight > scrollPos
        ) {
          document
            .querySelectorAll(".navbar-nav .nav-link")
            .forEach((navLink) => navLink.classList.remove("active"));
          currLink.classList.add("active");
          isActiveSet = true;
        }
      });

      if (!isActiveSet) {
        document
          .querySelectorAll(".navbar-nav .nav-link")
          .forEach((navLink) => navLink.classList.remove("active"));
      }

      handleFloatingScroll();
    };

    // Load Animations
    const headerAnimation = document.querySelector("header.animation");
    if (headerAnimation) {
      headerAnimation.classList.add("loaded");
    }

    // Event listeners
    checkWidth();
    window.addEventListener("resize", checkWidth);
    window.addEventListener("resize", updateMobileSizeStatus);
    window.addEventListener("scroll", navbarShrink);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", checkWidth);
      window.removeEventListener("resize", updateMobileSizeStatus);
      window.removeEventListener("scroll", navbarShrink);
      window.removeEventListener("scroll", handleScroll);
      if (scrollToTopBtn) {
        scrollToTopBtn.removeEventListener("click", () => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
      }
    };
  }, []);

  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <meta
            name="keywords"
            content="UMKM, Payment, kasir, invoice, Merchant, toko, digital, pembayaran, QRIS"
          />
          <title>
            Ojire Anywhere | Solusi Kasir Digital dan Payment Gateaway Indonesia
            #BikinUsahaNaikKelas
          </title>
        </Helmet>
        <div id="page-top">
          {/* Navigation */}
          <Navbar
            expand="lg"
            fixed="top"
            bg="light"
            variant="light"
            id="mainNav"
            className="py-2 align-center"
          >
            <Container className="px-3 justify-content-between">
              <Navbar.Brand href="/">
                <img
                  src="/img/Logo Ojire.png"
                  alt="Ojire Logo"
                  style={{ margin: "8px" }}
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarTogglerDemo02">
                <a
                  className="nav-toggle"
                  onClick={() =>
                    document.querySelector(".navbar-toggler").click()
                  }
                >
                  <span className="bar"></span>
                  <span className="bar"></span>
                  <span className="bar"></span>
                </a>
              </Navbar.Toggle>
              <Navbar.Collapse id="navbarTogglerDemo02">
                <Nav
                  className={`navbar-nav my-2 my-lg-0 ${
                    isMobile ? "mx-4" : "mx-auto"
                  }`}
                >
                  <Nav.Link href="#whyOjire" className="nav-link">
                    Kenapa Ojire
                  </Nav.Link>
                  <Nav.Link href="#fiturOjire" className="nav-link">
                    Fitur
                  </Nav.Link>
                  <Nav.Link href="#how" className="nav-link">
                    Cara Kerja
                  </Nav.Link>
                  <Nav.Link href="#faq" className="nav-link">
                    FAQ
                  </Nav.Link>
                  <Nav.Link href="/artikel" className="nav-link">
                    <span className="nav-link-last">Artikel</span>
                  </Nav.Link>
                </Nav>
                <div className="d-flex">
                  <a href="/demo">
                    <Button variant="primary">Daftar Sekarang &gt;</Button>
                  </a>
                </div>
              </Navbar.Collapse>
            </Container>
          </Navbar>

          {/* Scroll-to-top and WhatsApp float*/}
          <div className="floating">
            <img
              src="/img/PNG/ArrowCircleUp.png"
              id="scrollToTopBtn"
              className={
                scrollToTopVisible
                  ? "scroll-to-top-btn-fade-in"
                  : "scroll-to-top-btn-fade-out"
              }
              alt=""
            />
            <a
              className={`btn ${
                whatsappFloatingVisible
                  ? "btn-whatsapp-fade-in"
                  : "btn-whatsapp-fade-out"
              }`}
              id="whatsappChat"
              href="https://wa.me/6285215617198"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa-brands fa-whatsapp fa-2xl"></i>
            </a>
          </div>

          <div
            className={
              bottomBarVisible ? "bottom-bar-fade-in" : "bottom-bar-fade-out"
            }
          >
            <div className="d-flex">
              <a className="btn btn-primary" href="/demo">
                Hubungi Kami &gt;
              </a>
            </div>
          </div>

          {/* Masthead */}
          <header className="masthead">
            <div className="container h-100 position-relative">
              <div
                className="row align-items-center justify-content-center"
                id="heroContent"
              >
                <div
                  className="col-lg-6 hero-text"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <h1 className="fw-semibold hero-header">
                    <span className="header-yellow">Kasir Digital</span> Bikin
                    Usaha Naik Kelas
                  </h1>
                  <p className="my-4 mb-5">
                    Memudahkan menagih dan menerima transaksi pembayaran kapan
                    pun dan di mana pun.
                  </p>
                  <a className="btn btn-outline-primary" href="/demo">
                    Hubungi Kami &gt;
                  </a>
                </div>
                <div
                  className="col-lg-6 position-relative"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  id="heroImage"
                >
                  <img
                    className="hero-images"
                    src="/img/hero-images.png"
                    alt=""
                  />
                </div>
                <img
                  className="hero-accesories"
                  src="/img/Hero Accesories.png"
                  alt=""
                />
              </div>
            </div>
          </header>

          {/* Why Section */}
          <section
            className="page-section position-relative overflow-hidden"
            id="whyOjire"
          >
            <div className="container">
              <div className="row gx-4 gx-lg-5 justify-content-center">
                <div
                  className="col-lg-9 text-center pb-5"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <h5 className="font-weight-bold section-label text-primary mb-4">
                    <img
                      src="/img/PNG/bolt.png"
                      className="icon-section mx-2"
                      alt=""
                    />
                    KENAPA OJIRE
                  </h5>
                  <h1 className="fw-semibold text-primary">
                    <span className="header-yellow">Sebuah Solusi</span>
                    <br />
                    Untuk Kemajuan Bisnismu
                  </h1>
                </div>
              </div>

              <div
                className="row pt-5 text-center"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="col-lg-3 col-md-6 mb-4">
                  <div className="card h-100 border-0 ">
                    <div className="card-body p-0">
                      <img src="/img/why-illu-1.png" className="mb-3" alt="" />
                      <h5 className="card-title text-primary fst-italic">
                        All-in-One
                      </h5>
                      <p className="card-text">
                        Atur digitalisasi kasir, penyimpanan data, dan
                        infrastruktur teknologi dalam satu aplikasi.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 mb-4">
                  <div className="card h-100 border-0 ">
                    <div className="card-body">
                      <img src="/img/why-illu-2.png" className="mb-3" alt="" />
                      <h5 className="card-title text-primary">
                        Transaksi Aman
                      </h5>
                      <p className="card-text">
                        Transaksi aman dengan sistem kami yang berlisensi.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 mb-4">
                  <div className="card h-100 border-0 ">
                    <div className="card-body">
                      <img src="/img/why-illu-3.png" className="mb-3" alt="" />
                      <h5 className="card-title text-primary">
                        Biaya Transparan
                      </h5>
                      <p className="card-text">
                        Pemungutan biaya secara terbuka dan jelas.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 mb-4">
                  <div className="card h-100 border-0 ">
                    <div className="card-body">
                      <img src="/img/why-illu-4.png" className="mb-3" alt="" />
                      <h5 className="card-title text-primary fst-italic">
                        Help Center
                      </h5>
                      <p className="card-text">
                        Menyediakan layanan online untuk mengatasi kendala dan
                        masalah secara profesional dan handal.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Fitur */}
          <section className="page-section position-relative" id="fiturOjire">
            <div className="container position-relative">
              <img
                src="/img/feature-Accesories.png"
                className="feature-accesories"
                alt=""
              />
              <img
                src="/img/feature-Accesories2.png"
                className="feature-accesories2"
                alt=""
              />
              <div className="row gx-4 gx-lg-5 justify-content-center ">
                <div
                  className="col-lg-12 text-center pb-5"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <h5 className="font-weight-bold section-label text-white mb-4">
                    <img
                      src="/img/PNG/ShootingStar.png"
                      className="icon-section mx-2"
                      alt=""
                    />
                    FITUR
                  </h5>
                  <h1 className="fw-semibold text-white">
                    <span className="header-yellow">
                      Kelola Kasir Digital Dan Keuangan Toko
                    </span>
                    <br />
                    dengan Mudah dan Aman
                  </h1>
                </div>
              </div>

              {/* All-in-1 Section */}
              <div className="row row-cols-1 row-cols-md-3 g-3 pt-4">
                <div className="" data-aos="zoom-in" data-aos-duration="800">
                  <div className="card text-start aspect-ratio">
                    <img
                      src="/img/feature/feature-1.png"
                      className="card-img-top"
                      alt="Kelola Daftar Pelanggan"
                    />
                    <div className="card-body">
                      <h5 className="card-title">Kelola Daftar Pelanggan</h5>
                      <p className="card-text">
                        Atur daftar pelangganmu dengan mudah.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="" data-aos="zoom-in" data-aos-duration="800">
                  <div className="card text-start aspect-ratio">
                    <img
                      src="/img/feature/feature-2.png"
                      className="card-img-top"
                      alt="Fleksibel"
                    />
                    <div className="card-body">
                      <h5 className="card-title">Fleksibel</h5>
                      <p className="card-text">
                        Kelola transaksi dan data operasional kapan pun dan di
                        mana pun.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="" data-aos="zoom-in" data-aos-duration="800">
                  <div className="card text-start aspect-ratio">
                    <img
                      src="/img/feature/feature-3.png"
                      className="card-img-top"
                      alt="Diskon"
                    />
                    <div className="card-body">
                      <h5 className="card-title">Diskon</h5>
                      <p className="card-text">
                        Atur diskon dalam setiap transaksi sesuai kebutuhan.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="" data-aos="zoom-in" data-aos-duration="800">
                  <div className="card text-start aspect-ratio">
                    <img
                      src="/img/feature/feature-4.png"
                      className="card-img-top"
                      alt="Kelola Daftar Produk"
                    />
                    <div className="card-body">
                      <h5 className="card-title">Kelola Daftar Produk</h5>
                      <p className="card-text">
                        Lihat dan kelola semua produk penjualan untuk kebutuhan
                        transaksi.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="" data-aos="zoom-in" data-aos-duration="800">
                  <div className="card card-brand text-start aspect-ratio">
                    <div className="card-body align-items-center">
                      <img
                        src="/img/ojire-anywhere.png"
                        className="card-img-top"
                        style={{ width: "203px" }}
                        alt="Ojire Anywhere Logo"
                      />
                      <h1 className="card-title text-white">All-in-1</h1>
                    </div>
                  </div>
                </div>
                <div className="" data-aos="zoom-in" data-aos-duration="800">
                  <div className="card text-start aspect-ratio">
                    <img
                      src="/img/feature/feature-5.png"
                      className="card-img-top"
                      alt="e-Invoice"
                    />
                    <div className="card-body">
                      <h5 className="card-title">e-Invoice</h5>
                      <p className="card-text">
                        Buat dan bagi e-Invoice melalui Whatsapp, email dan/atau
                        media sosial lainnya.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="" data-aos="zoom-in" data-aos-duration="800">
                  <div className="card text-start aspect-ratio">
                    <img
                      src="/img/feature/feature-6.png"
                      className="card-img-top"
                      alt="e-Receipt"
                    />
                    <div className="card-body">
                      <h5 className="card-title">e-Receipt</h5>
                      <p className="card-text">
                        Kirim e-receipt via email, kapan pun dan di mana pun.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="" data-aos="zoom-in" data-aos-duration="800">
                  <div className="card text-start aspect-ratio">
                    <img
                      src="/img/feature/feature-7.png"
                      className="card-img-top"
                      alt="Riwayat Transaksi"
                    />
                    <div className="card-body">
                      <h5 className="card-title">Riwayat Transaksi</h5>
                      <p className="card-text">
                        Pantau riwayat pembayaran dari pelanggan dalam satu
                        fitur.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="" data-aos="zoom-in" data-aos-duration="800">
                  <div className="card text-start aspect-ratio">
                    <img
                      src="/img/feature/feature-8.png"
                      className="card-img-top"
                      alt="Kasir Digital"
                    />
                    <div className="card-body">
                      <h5 className="card-title">Kasir Digital</h5>
                      <p className="card-text">
                        Terima pembayaran melalui: Virtual Account, QRIS dan
                        Kartu Kredit.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="multi-bg">
            {/* How it works */}
            <section className="page-section position-relative" id="how">
              <div className="container ">
                <div className="row gx-4 gx-lg-5 justify-content-center ">
                  <div className="col-lg-8 text-center pb-5" data-aos="fade-up">
                    <h5 className="font-weight-bold section-label text-primary mb-4">
                      <img
                        src="/img/PNG/suitcase.png"
                        className="icon-section mx-2"
                        alt=""
                      />
                      CARA KERJA
                    </h5>
                    <h1 className="fw-semibold mb-4 text-primary">
                      <span className="header-yellow">Alur</span>
                      <br />
                      Penjualan Toko
                    </h1>
                  </div>

                  <div className="container pt-5" data-aos="fade-up">
                    <div className="row text-center">
                      {/* Step 1 */}
                      <div className="col-md-4 position-relative p-0">
                        <div className="workflow-step position-relative">
                          <div className="step-number">1</div>
                          <img
                            src="/img/Step1.png"
                            alt="Step 1 Image"
                            className="step-image img-fluid"
                          />
                          <div className="step-content">
                            <h5 className="step-title fw-semibold">
                              Tambah Produk
                            </h5>
                            <p className="step-description">
                              Menambahkan produk yang dipilih pelanggan kedalam
                              total produk.
                            </p>
                          </div>
                        </div>
                        <img
                          src="/img/Arrow.png"
                          alt="Arrow"
                          className="arrow-image"
                        />
                      </div>
                      {/* Step 2 */}
                      <div className="col-md-4 position-relative p-0">
                        <div className="workflow-step position-relative">
                          <div className="step-number">2</div>
                          <img
                            src="/img/Step2.png"
                            alt="Step 2 Image"
                            className="step-image img-fluid"
                          />
                          <div className="step-content">
                            <h5 className="step-title fw-semibold">
                              Buat e-Invoice
                            </h5>
                            <p className="step-description">
                              Mengirimkan e-Invoice melalui Whatsapp, email, dan
                              / atau media sosial lainnya.
                            </p>
                          </div>
                          <img
                            src="/img/share.png"
                            alt="share"
                            className="share"
                          />
                        </div>
                        <img
                          src="/img/Arrow.png"
                          alt="Arrow"
                          className="arrow-image"
                        />
                      </div>
                      {/* Step 3 */}
                      <div className="col-md-4 position-relative p-0">
                        <div className="workflow-step mb-0 position-relative">
                          <div className="step-number">3</div>
                          <img
                            src="/img/Step3.png"
                            alt="Step 3 Image"
                            className="step-image img-fluid"
                          />
                          <div className="step-content">
                            <h5 className="step-title fw-semibold">
                              Terima Pembayaran
                            </h5>
                            <p className="step-description">
                              {" "}
                              Menyediakan pilihan pembayaran melalui Virtual
                              Account, Kartu Kredit dan QRIS.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* CTA */}
            <section className="page-section position-relative pb-0" id="cta">
              <div className="container position-relative">
                <div className="row justify-content-center ">
                  <img
                    className="cta-accesories"
                    src="/img/CTA Acc.1.png"
                    alt=""
                  />
                  <img
                    className="cta-accesories2"
                    src="/img/CTA acc.2.png"
                    alt=""
                  />
                  <div className="col-lg-8 text-center cta-content">
                    <h5 className="font-weight-bold section-label">
                      <img
                        src="/img/PNG/DownloadSimple.png"
                        className="icon-section mx-2"
                        alt=""
                      />
                      UNDUH
                    </h5>
                    <h1 className="fw-semibold text-white my-2">
                      <span className="header-yellow">
                        #BikinUsahaNaikKelas
                      </span>
                      <br />
                    </h1>
                    <h5 className="mb-5">
                      unduh <img src="/img/oa-title.png" height="44" alt="" />
                      sekarang!
                    </h5>
                    <a
                      href="https://play.google.com/store/apps/details?id=id.co.ojire.app.ojireanywhere&pcampaignid=web_share"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="/img/download-app.png"
                        className="download-app"
                        alt="image cta"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div className="multi-bg2">
            {/* Partners */}
            <section className="page-section" id="partners" data-aos="fade-up">
              <div className="container">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                  <div className="col-lg-8 text-center title">
                    <h5 className="mt-0 fw-semibold text-primary">
                      Rekanan Kami
                    </h5>
                  </div>
                  <div className="partners-img">
                    <img
                      className="cimb-logo-mobile"
                      src="/img/Partners Logo/CIMB_Niaga_logo 1.png"
                      alt="Image of CIMB"
                      srcSet=""
                    />
                    <img
                      className="bri-logo-mobile"
                      src="/img/Partners Logo/BANK_BRI_logo 1.png"
                      alt="Image of BRI"
                      srcSet=""
                    />
                    <img
                      className="bni-logo-mobile"
                      src="/img/Partners Logo/BNI.png"
                      alt="Image of BNI"
                      srcSet=""
                    />
                  </div>
                </div>
              </div>
            </section>

            {/* ======= Frequently Asked Questions Section ======= */}
            <section className="faq page-section pt-0" id="faq">
              <div className="container">
                <div className="section-title text-center">
                  <h1 className="mt-0 mb-5 text-primary fw-semibold">FAQ</h1>
                </div>

                <Container className="w-100 mx-auto mt-5 faq-mobile-pad">
                  <Accordion id="faqAccordion">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        Apakah ada biaya pendaftaran pada Ojire Anywhere?
                      </Accordion.Header>
                      <Accordion.Body>
                        Tidak ada biaya pendaftaran apapun.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        Apa saja persyaratan dan dokumen yang diperlukan untuk
                        membuat akun di Ojire Anywhere?
                      </Accordion.Header>
                      <Accordion.Body>
                        Selain melengkapi data diri, calon pengguna harus
                        mengunggah foto KTP, NPWP dan halaman buku tabungan yang
                        mencantumkan nomor rekening, nama pemilik rekening, dan
                        alamat cabang dari bank terkait.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        Metode pembayaran apa saja yang didukung oleh Ojire
                        Anywhere?
                      </Accordion.Header>
                      <Accordion.Body>
                        Saat ini Ojire Anywhere mendukung pembayaran via
                        transfer rekening virtual BRI dan BNI, QRIS, dan Kartu
                        Kredit.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        Apakah ada biaya tambahan yang dikenakan untuk pelanggan
                        saya?
                      </Accordion.Header>
                      <Accordion.Body>
                        Ojire tidak melakukan biaya tambahan terhadap transaksi
                        yang terjadi, biaya tersebut terjadi dengan kesepatan
                        antara pembeli dan penjual. Ojire hanya memberikan
                        fasilitas kemudahan bagi jenis transaksi tersebut
                        apabila diperlukan.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>
                        Apakah saya dapat memonitor transaksi penjualan secara
                        real time pada aplikasi Ojire Anywhere?
                      </Accordion.Header>
                      <Accordion.Body>
                        Status transaksi dapat dilihat secara real time dalam
                        aplikasi Ojire Anywhere, mempermudah merchant memantau
                        perkembangan status transaksi dan memastikan selesainya
                        proses pembayaran dari mana saja dan kapan saja.
                      </Accordion.Body>
                    </Accordion.Item>
                    {/* Add more Accordion.Item components here for each accordion item */}
                  </Accordion>
                </Container>
              </div>
            </section>
          </div>

          {/* End Frequently Asked Questions Section */}

          {/* Footer */}
          <footer className="text-center footer-text-color footer-font-size">
            <section className="pt-2">
              <div className="container text-center text-md-start mt-4">
                {/* Grid row */}
                <div className="row">
                  {isMobile ? (
                    <div>
                      <a className="" href="#page-top">
                        <img
                          src="/img/Logo Ojire.png"
                          className="mb-5"
                          height="70"
                          alt=""
                        />
                      </a>
                      <div className="gap-3 d-flex justify-content-center mb-5">
                        <a
                          href="https://www.facebook.com/Ojire.ID"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="align-content-center text-reset"
                        >
                          <img
                            src="/img/PNG/facebook.png"
                            className=""
                            width="32"
                            alt=""
                          />
                        </a>
                        <a
                          href="https://id.linkedin.com/company/ojire"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="align-content-center text-reset"
                        >
                          <img
                            src="/img/PNG/linkedin.png"
                            className=""
                            width="32"
                            alt=""
                          />
                        </a>
                        <a
                          href="https://www.instagram.com/ojireofficial/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="align-content-center text-reset"
                        >
                          <img
                            src="/img/PNG/instagram.png"
                            className=""
                            width="32"
                            alt=""
                          />
                        </a>
                        <a
                          href="https://www.youtube.com/@Ojireofficial"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="align-content-center text-reset"
                        >
                          <img
                            src="/img/PNG/youtube.png"
                            className=""
                            width="32"
                            alt=""
                          />
                        </a>
                        <a
                          href="https://www.tiktok.com/@ojireofficial?is_from_webapp=1&sender_device=pc"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="align-content-center text-reset"
                        >
                          <img
                            src="/img/PNG/tiktok.png"
                            className=""
                            width="32"
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-between mb-3">
                      <a className="" href="#page-top">
                        <img
                          src="/img/Logo Ojire.png"
                          className=""
                          height="44"
                          alt=""
                        />
                      </a>
                      <div className="gap-3 d-flex">
                        <a
                          href="https://www.facebook.com/Ojire.ID"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="align-content-center text-reset"
                        >
                          <img
                            src="/img/PNG/facebook.png"
                            className=""
                            width="32"
                            alt=""
                          />
                        </a>
                        <a
                          href="https://id.linkedin.com/company/ojire"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="align-content-center text-reset"
                        >
                          <img
                            src="/img/PNG/linkedin.png"
                            className=""
                            width="32"
                            alt=""
                          />
                        </a>
                        <a
                          href="https://www.instagram.com/ojireofficial/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="align-content-center text-reset"
                        >
                          <img
                            src="/img/PNG/instagram.png"
                            className=""
                            width="32"
                            alt=""
                          />
                        </a>
                        <a
                          href="https://www.youtube.com/@Ojireofficial"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="align-content-center text-reset"
                        >
                          <img
                            src="/img/PNG/youtube.png"
                            className=""
                            width="32"
                            alt=""
                          />
                        </a>
                        <a
                          href="https://www.tiktok.com/@ojireofficial?is_from_webapp=1&sender_device=pc"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="align-content-center text-reset"
                        >
                          <img
                            src="/img/PNG/tiktok.png"
                            className=""
                            width="32"
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                  )}
                  {/* Offices column */}
                  <div className="col-md-4 col-lg-4 col-xl-4 px-sm-2 px-5 mx-auto my-3">
                    {/* Content */}
                    <div className="d-flex mb-2 footer-address-center">
                      <i className="fa-solid fa-location-dot fa-lg me-2 mt-1"></i>
                      <span className="fw-bold footer-font-size footer-office-indent">
                        Kantor Pusat
                      </span>
                    </div>
                    <div className="d-flex footer-address-center">
                      <a
                        href="https://goo.gl/maps/7P7Bt21Wqhf7xP46A"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-decoration-none footer-text-color footer-font-small footer-link-highlight footer-address-indent"
                      >
                        Gedung One Pacific Place Lt. 15, Suite 15-01 <br />
                        Jl. Jendral Sudirman Kav. 52-53, Jakarta 12190
                      </a>
                    </div>
                    <div className="d-flex footer-address-center mb-4">
                      <a
                        href="tel:+622125502533"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-decoration-none footer-text-color footer-font-small footer-link-highlight footer-address-indent"
                      >
                        (021) 2550 2533
                      </a>
                    </div>

                    <div className="d-flex mb-2 footer-address-center">
                      <i className="fa-solid fa-location-dot fa-lg me-2 mt-1"></i>
                      <span className="fw-bold footer-font-size footer-office-indent">
                        Kantor Operasional
                      </span>
                    </div>
                    <div className="d-flex footer-address-center">
                      <a
                        href="https://goo.gl/maps/BYfWparehGeqP8kMA"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-decoration-none footer-text-color footer-font-small footer-link-highlight footer-address-indent"
                      >
                        Jl. Pluit Kencana Raya No.136, Jakarta 14450
                      </a>
                    </div>
                    <div className="d-flex footer-address-center mb-4">
                      <a
                        href="tel:+62216691609"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-decoration-none footer-text-color footer-font-small footer-link-highlight footer-address-indent"
                      >
                        (021) 669 1609
                      </a>
                    </div>
                    {isMobile ? null : (
                      <a
                        href="https://play.google.com/store/apps/details?id=id.co.ojire.app.ojireanywhere&pcampaignid=web_share"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="/img/gplay-button.png"
                          style={{ maxWidth: "160px", width: "100%" }}
                          className=" mt-1"
                          alt=""
                        />
                      </a>
                    )}
                  </div>

                  {/* Abuot Ojire */}
                  <div className="col-md-2 col-lg-2 col-xl-2 mx-auto my-3 product footer-font-size">
                    <h6 className="text-uppercase fw-bold mb-4 footer-title-color footer-font-size footer-nav-title-mb">
                      Tentang Ojire
                    </h6>

                    <div className="footer-nav-mb">
                      <a
                        href="/#whyOjire"
                        className="text-reset text-decoration-none"
                      >
                        Kenapa Ojire
                      </a>
                    </div>
                    <div className="footer-nav-mb">
                      <a
                        href="/#fiturOjire"
                        className="text-reset text-decoration-none"
                      >
                        Fitur
                      </a>
                    </div>
                    <div className="footer-nav-mb">
                      <a
                        href="/#how"
                        className="text-reset text-decoration-none"
                      >
                        Cara Kerja
                      </a>
                    </div>
                    <div className="footer-nav-mb">
                      <a
                        href="/#faq"
                        className="text-reset text-decoration-none"
                      >
                        FAQ
                      </a>
                    </div>
                    <div className="footer-nav-mb">
                      <a
                        href="/artikel"
                        className="text-reset text-decoration-none"
                      >
                        Artikel
                      </a>
                    </div>
                  </div>

                  {/* Panduan column */}
                  <div className="col-md-2 col-lg-2 col-xl-2 mx-auto my-3 mb-md-0 footer-font-size">
                    {/* Links */}
                    <h6 className="text-uppercase fw-bold mb-4 footer-title-color footer-font-size">
                      Panduan
                    </h6>
                    <a
                      href="/layanan"
                      className="text-decoration-none footer-nav-mb footer-text-color footer-link-highlight d-block"
                    >
                      Layanan Pengaduan
                    </a>
                    <a
                      href="https://ojire.support/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-decoration-none footer-nav-mb footer-text-color footer-link-highlight d-block"
                    >
                      Bantuan
                    </a>
                  </div>

                  {/* Pengaduan column */}
                  <div
                    className="col-md-4 col-lg-4 col-xl-4 mx-auto my-3"
                    id="licensed"
                  >
                    <div className="ms-xl-5">
                      <h6 className="text-uppercase fw-bold mb-2 footer-title-color footer-font-size">
                        Layanan Pengaduan Konsumen
                      </h6>
                      <div className="text-decoration-none footer-text-color footer-font-small pengaduan-line-height d-block">
                        PT. Ojire Teknologi Informasi <br />
                        Senin - Jumat (08.00 - 17.00 WIB) <br />
                        Email: halo@ojire.com <br />
                        Telepon: (021) 2188 0008 <br />
                        Chat Whatsapp: 0852 1561 7198
                      </div>
                      <div className="text-decoration-none fw-bold footer-text-color footer-font-small d-block mt-3 mb-2">
                        Direktorat Jendral Perlindungan Konsumen dan Tertib
                        Niaga, Kementrian Perdagangan Republik Indonesia
                      </div>
                      <div className="text-decoration-none footer-text-color footer-font-small d-block">
                        Whatsapp: 0853-1111-1010
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div className="container text-center py-4" id="copyright">
              <hr className="footer-custom-hr pb-2" />
              {isMobile ? (
                <div className="mx-auto" id="licensed">
                  <a
                    href="https://play.google.com/store/apps/details?id=id.co.ojire.app.ojireanywhere&pcampaignid=web_share"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/img/gplay-button.png"
                      style={{ maxWidth: "200px", width: "100%" }}
                      className=" mt-4 mb-4"
                      alt="Google Play"
                    />
                  </a>
                  <img
                    src="/img/license-bi.png"
                    style={{
                      marginBottom: "12px",
                      maxWidth: "200px",
                      width: "100%",
                    }}
                    alt=""
                  />
                  <div className="d-flex">
                    <img
                      src="/img/kominfo.png"
                      className="license-logo"
                      style={{
                        marginRight: "12px",
                        maxWidth: "88px",
                      }}
                      alt=""
                    />
                    <div className="d-flex flex-column">
                      <img
                        src="/img/aspi.png"
                        className="license-logo mb-2"
                        alt=""
                      />
                      <img
                        src="/img/pci-dss.png"
                        className="license-logo mb-auto"
                        alt=""
                      />
                    </div>
                  </div>
                  <img
                    src="/img/apgi.png"
                    className="mt-3"
                    style={{
                      maxWidth: "100px",
                      width: "50%",
                    }}
                    alt=""
                  />
                  <div className="d-flex justify-content-center mx-5 my-5">
                    © Copyright PT. Ojire Teknologi Informasi 2025
                  </div>
                </div>
              ) : (
                <div className="d-flex justify-content-between mb-5">
                  <div className="d-flex gap-3">
                    <img
                      src="/img/license-bi.png"
                      className="footer-license-bi"
                      alt="BI"
                    />
                    <img
                      src="/img/kominfo.png"
                      className="footer-license-kominfo"
                      alt="Kominfo"
                    />
                    <img
                      src="/img/pci-dss.png"
                      className="footer-license-pcidss"
                      alt="PCIDSS"
                    />
                    <img
                      src="/img/apgi.png"
                      className="footer-license-apgi"
                      alt="APGI"
                    />
                    <img
                      src="/img/aspi.png"
                      className="footer-license-aspi"
                      alt="ASPI"
                    />
                  </div>
                  <div className="footer-copyright-container">
                    © Copyright PT. Ojire Teknologi Informasi 2025
                  </div>
                </div>
              )}
            </div>
          </footer>
          {/* Footer*/}
        </div>
      </div>
    </HelmetProvider>
  );
};

export default MainPage;
