import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import MainPage from "./pages/MainPage";
import DemoPage from "./pages/Demo";
import ServicePage from "./pages/Layanan";
import PrototypesPage from "./pages/Prototypes";
import BlogPage from "./pages/Artikel";
import ArticlePage from "./pages/TemplatArtikel";
import ReactGA from "react-ga4";
import { hotjar } from "react-hotjar";
import { Helmet, HelmetProvider } from "react-helmet-async";

// Google Analytics
ReactGA.initialize("G-0TLZSB4FYF");

const AppContent = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.hj) {
      hotjar.stateChange(location.pathname);
    }
  }, [location]);

  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<MainPage />} />
        <Route exact path="/demo" element={<DemoPage />} />
        <Route exact path="/layanan" element={<ServicePage />} />
        <Route exact path="/prototypes" element={<PrototypesPage />} />
        <Route exact path="/artikel" element={<BlogPage />} />
        <Route exact path="/artikel/page/:pageNumber" element={<BlogPage />} />
        <Route exact path="/artikel/:slug" element={<ArticlePage />} />
      </Routes>
    </div>
  );
};

const App = () => {
  useEffect(() => {
    hotjar.initialize("5191148", 6);
  }, []);

  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <meta
            name="keywords"
            content="UMKM, Payment, kasir, invoice, Merchant, toko, digital, pembayaran, QRIS"
          />
          <title>
            Ojire Anywhere | Solusi Kasir Digital dan Payment Gateaway Indonesia
            #BikinUsahaNaikKelas
          </title>
        </Helmet>
        <Router>
          <AppContent />
        </Router>
      </div>
    </HelmetProvider>
  );
};

export default App;
